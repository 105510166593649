import {
  Pricing,
  CurrencyType,
  PaymentType,
  CheckInStatus,
} from '../entities/enums';
import { GuestsWithAgeGroup } from './guestsWithAgeGroup';
import { ReservationActivityDto } from './reservationActivity.dto';
import { ReservationAddonDto } from './reservationAddon.dto';
import { ReservationResourceDto } from './reservationResource.dto';

export class ReservationDto {
  id?: string;
  number?: string;
  status?: string;
  date: string;
  slots: number;
  duration: number;
  guests: number;
  guestsWithAgeGroups?: GuestsWithAgeGroup[];
  lanes: number;
  venueId: string;
  price: number;
  deposit: number;
  depositParam: number;
  depositType: string;
  textBox: string;
  tax: number;
  serviceFee: number;
  customFees: string;
  discount: number;
  pricing: Pricing;
  timeSlotDuration: number;
  startTime?: number;
  endTime?: Date;
  allowCancellation: boolean;
  allowModification: boolean;
  timeZone: string;
  venueName: string;
  venueAddress: string;
  venueImage?: string;
  secondaryVenueImages?: string[];
  timeSlotShifting: number;
  discountName?: string;
  occasion?: string;
  customNotes?: string;
  email?: string;
  packageId?: string;
  packageName?: string;
  preBufferTime: number;
  bufferTime: number;
  customFieldsInformation?: string;
  currency: CurrencyType;
  giftCardPaid: number;
  paymentType?: PaymentType;
  modificationFee: number;
  modified: boolean;
  addonsPrice: number;
  customTaxes: string;
  addonCustomTaxes: string;
  customFeesTaxes: string;
  adminNotes?: string;
  createdByAdmin: boolean;
  firstName: string;
  lastName: string;
  payed: number;
  refunded: number;
  checkedIn: boolean;
  checkInStatus: CheckInStatus;
  goTabId?: string;
  startTimer?: Date;
  couponCodes?: string[];
  coupons?: CouponDto[];
  discountAmount: number;
  total?: number;
  token?: string;
  addons?: ReservationAddonDto[];
  squareInvoiceId?: string;
  squareOrderId?: string;
  markAsPaidSource?: string;
  managersCompensation?: number;
  paymentMethods?: string;
  reminderTextSent?: boolean;
  stripeInvoiceId?: string;
  hasAddons?: boolean;
  addonsByGuestsCount?: number;
  isSyncProblem?: boolean;
  discounts: string;
  reservationActivities?: ReservationActivityDto[];
  activitiesPrice: number;
  inRangePin?: string;
  resourceName?: string;
}

export class ReservationAlternativePaymentDto {
  paymentMethod?: string;
  otherMethods?: boolean;
}

export class CreateReservationDto {
  date: string;
  slots: number;
  duration: number;
  guests: number;
  guestsWithAgeGroups?: GuestsWithAgeGroup[];
  lanes: number;
  venueId: string;
  packageId?: string;
  textBox: string;
  occasion?: string;
  guestDetail?: string;
  discountName: string;
  payed?: number;
  couponCodes?: string[];
  markAsPaidSource?: string;
  managersCompensation?: number;
  stripeTerminal?: string;
  reminderTextSent?: boolean;
  stripeInvoiceId?: string;
  discounts?: string;
  squareTerminal?: string;
}

export class UpdateReservationDto {
  id: string;
  date: string;
  slots: number;
  duration: number;
  guests: number;
  guestsWithAgeGroups?: GuestsWithAgeGroup[];
  lanes: number;
  venueId: string;
  price: number;
  deposit: number;
  discount: number;
  depositParam: number;
  depositType: string;
  textBox: string;
  occasion?: string;
  guestDetail?: string;
  timeSlotShifting: number;
  timeSlotDuration: number;
  allowCancellation: boolean;
  timeZone: string;
  venueName: string;
  venueAddress: string;
  discountName: string;
  packageId?: string;
  preBufferTime: number;
  bufferTime: number;
  currency: CurrencyType;
  addonsPrice: number;
  payed?: number;
  couponCodes?: string[];
  markAsPaidSource?: string;
  managersCompensation?: number;
  stripeTerminal?: string;
  stripeInvoiceId?: string;
  discounts?: string

}

export class ReservationConfirmationDto {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  occasion?: string;
  guestDetail?: string;
  customNotes?: string;
  customFieldsInformation?: string;
  subscribeToOffers?: boolean;
  sendPaymentLink?: boolean;
  adminNotes?: string;
  aboutGolfBrowserId?: string;
  phoneForPaymentLink?: string;
  tags?: string[]
}

export type CouponDto = { name: string, code: string };

export class ReservationPriceDto {
  price: number;
  deposit: number;
  tax: number;
  serviceFee: number;
  modificationFee: number;
  customFees: string;
  discount: number;
  discountName: string;
  addonsPrice: number;
  customTaxes: string;
  addonCustomTaxes: string;
  customFeesTaxes: string;
  discountAmount: number;
  total: number;
  couponCodes?: string[];
  coupons?: CouponDto[];
  managersCompensation?: number;
  discounts: string;
  reservationActivities?: ReservationActivityDto[];
  activitiesPrice: number;
  resourceName?: string;
}

export class StripePaymentLoggerDto {
  payload: any;
  reservationId?: string;
  confirmation: ReservationConfirmationDto;
}

export class CreateSquarePartialPaymentDto {
  invoiceId?: string;
  reservation: ReservationDto;
  checkoutId?: string;
}

export class CreateSquareTerminalPaymentDto {
  checkoutId: string;
  reservation: ReservationDto;
}


export class PreExtendReservationTimeDto {
  oldTotal: number;
  newTotal: number;
  oldPrice: number;
  newPrice: number;
  oldDuration: number;
}