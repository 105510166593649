import { Box, Typography } from "@material-ui/core";
import { chunk } from "lodash";
import React, { useState } from 'react';
import { ConnectedProps, connect } from "react-redux";
import HtmlRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { PrimaryButton } from "../../../../../common/components/PrimaryButton/PrimaryButton";
import { getPackageDurations } from "../../../../../common/utils/formats";
import { backToReservationAction, changeActivityAction } from '../../../actions/venue-actions';
import { useViewport } from '../../../hooks/responsive';
import { selectClient } from "../../../reducers/auth-reducer";
import { selectGiftCardAmount, selectIsRequestInProgress, selectIsUpdateReservation, selectOldReservation, selectReservation, selectReservationAddons, selectSelectedActivities } from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentActivity, selectCurrentPackage, selectCurrentPackageActivities, selectError, selectIsLoadingVenue, selectVenue } from "../../../reducers/venues";
import { CurrencyType, PackageName, Pricing, State } from "../../../store/types";
import { getAgeGroupsMinGuests } from "../../MakePackageReservation/utils";
import NoAvailiableGuests from '../../PackageCard/NoAvailiableGuests';
import PackageSkeletons from "../../PackageCard/PackageSkeletons";
import ButtonMain from '../../common/ButtonMain/ButtonMain';
import MobileAttachedButton from "../../common/MobileAttachedButton/MobileAttachedButton";
import './Activities.scss';
import { OrderSummary } from "../../OrderSummary/OrderSummary";
import { TimeButtonType } from "../../MakePackageReservation/MakePackageReservation";
import { startBookingAction } from "../../../actions/reservation-actions";
import { composeActivityTimeSlots } from "../../../utils/activities";
import { BookingStep } from "../../../utils/steps";
import ActivityCard from "../../ActivityCard/ActivityCard";
import _ from "lodash";
import "../../../components/MakePackageReservation/makePackageReservation.scss";

const mapStateToProps = (state: State) => ({
    uiConfig: selectUIConfig(state),
    currentPackage: selectCurrentPackage(state),
    reservation: selectReservation(state),
    activeVenue: selectVenue(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    oldReservation: selectOldReservation(state),
    giftCardAmount: selectGiftCardAmount(state),
    reservationAddons: selectReservationAddons(state),
    isRequestInProgress: selectIsRequestInProgress(state),
    client: selectClient(state),
    currentPackageActivities: selectCurrentPackageActivities(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    currentActivity: selectCurrentActivity(state),
    selectedActivities: selectSelectedActivities(state),
    error: selectError(state)
});

const mapDispatchToProps = {
    backToReservation: backToReservationAction,
    changeActivity: changeActivityAction,
    startBooking: startBookingAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);


const Activities = (
    {
        uiConfig,
        currentPackage,
        reservation,
        activeVenue,
        isUpdateReservation,
        giftCardAmount,
        backToReservation,
        reservationAddons,
        isRequestInProgress,
        currentPackageActivities,
        startBooking,
        changeActivity,
        currentActivity,
        selectedActivities,
        error
    }: ConnectedProps<typeof connector>
) => {
    const isHideDuration = uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const { isMobile, isTabletScreen } = useViewport();
    const { date } = reservation;
    const [showRequiredError, setShowRequiredError] = useState(false);
    const [selectActivityId, setSelectedActivityId] = useState<string | null>(null);
    const storedActivity = selectedActivities?.find((activity) => activity.packageId === currentActivity?.id);
    const handleToggleShowMoreInfo = (packageId: string) => {
        setSelectedActivityId((prevId) => (prevId === packageId ? null : packageId));
    };
    const onContinue = () => {
        const requiredActivities = currentPackageActivities?.filter((activity) => activity.required);
        const selectedRequiredActivities = _.filter(
            _.get(reservation, 'reservationActivities', []),
            activity => _.some(requiredActivities, { id: activity.packageId })
        );

        if (!_.isEqual(requiredActivities.length, selectedRequiredActivities.length)) {
            setShowRequiredError(true);
            return
        }

        setShowRequiredError(false);
        if (activeVenue) {
            startBooking({ venue: activeVenue, date, currentPackage, currentStep: BookingStep.Activities });
        }


    }
    const onChangeActivity = (updatePackage: PackageName, guests: number) => {

        if (updatePackage.id === currentActivity?.id) {
            return;
        }
        const isOriginalPackageGuestSelectionAllowed = reservation.guests >= updatePackage.minGuests && reservation.guests <= updatePackage.maxGuests;
        const alreadySelectedGuests = isOriginalPackageGuestSelectionAllowed ? reservation.guests : updatePackage.minGuests
        const totalGuests = getAgeGroupsMinGuests(updatePackage, guests) || alreadySelectedGuests;
        const guestsWithAgeGroups = (updatePackage.method === Pricing.ageGroupsFlatRate || updatePackage.method === Pricing.ageGroupsPerHour) ?
            updatePackage?.ageGroups?.map((group) => ({
                ageGroup: group.groupName,
                count: group.minQty,
            })) : undefined;
        if (updatePackage.enableDurationChoice) {
            const parsedDuration = getPackageDurations(updatePackage)[0];

            changeActivity({
                updatePackage,
                date,
                guests: totalGuests,
                selectDuration: parsedDuration,
                guestsWithAgeGroups
            });
        } else {

            changeActivity({
                updatePackage, date, guests: totalGuests,
                guestsWithAgeGroups
            });
        }
    };

    const setDuration = (selectedPackage: PackageName, duration: number | null) => {
        if (!duration) return;
        if (isNaN(duration) || !selectedPackage.id) {
            return;
        }

        changeActivity({
            updatePackage: selectedPackage,
            date,
            selectDuration: duration,
            guests: storedActivity?.guests || selectedPackage.minGuests,
            isUpdateExisting: true
        });
    };
    const isShowReservationTime = uiConfig?.showReservationTime;

    const currency = uiConfig?.currency || CurrencyType.USD;
    const [isLessTreeTimeSlots, setIsLessTreeTimeSlots] =
        useState<boolean>(false);
    const timeButtons =
        closed || !currentActivity?.timeSlots || activeVenue?.packages.length === 0 || !!error
            ? []
            : composeActivityTimeSlots({
                date,
                venue: activeVenue,
                duration: storedActivity?.duration,
                isShowReservationTime,
                isHideDuration,
                guests: storedActivity?.guests,
                currentActivity,
                currency,
                twelveHourClockFormat,
            });
    const getTimeButtonsGroup = (timeButtons: TimeButtonType[], isRanges?: boolean) => {
        if (timeButtons.length <= 2) {
            if (isLessTreeTimeSlots === false) {
                setIsLessTreeTimeSlots(true);
            }
            // Return two separate chunks for 2 time slots, so they appear in separate rows on mobile
            return isMobile ? chunk(timeButtons, 2) : chunk(timeButtons, 1);
        } else {
            if (isLessTreeTimeSlots === true) {
                setIsLessTreeTimeSlots(false);
            }
            // Adjust chunk size based on screen size and reservation time visibility
            return chunk(
                timeButtons,
                (isShowReservationTime || isMobile) ? 3 : 4
            );
        }
    };

    const timeButtonsGroups = getTimeButtonsGroup(timeButtons, isShowReservationTime);

    const isContinueDisabled = false // to be updated
    const isHavingRequiredActivities = currentPackageActivities?.some((activity) => activity.required);
    return (
        <div className="activities-container">
            <div className={`activities-inner-container main-content-v2  ${(isMobile) ? `v2-mobile` : ''} ${(isTabletScreen) ? `v2-tablet` : ''}`}>
                <div
                    className={`activities ${(!isMobile) ? "activities-v2" : ""} ${(isMobile) ? "activities-v2-mobile" : ""} ${(isTabletScreen) ? "activities-v2-tablet" : ""} `}
                >
                    <Typography className="title">
                        <HtmlRenderer html={activeVenue?.activitiesTitle || "Additional Activities"} />
                    </Typography>
                    <Typography className="sub-title">
                        <HtmlRenderer html={activeVenue?.activitiesDescription || "Put additional activities"} />
                    </Typography>


                    <Box mb={'32px'}>
                        {(currentPackageActivities.length === 0) ?
                            <PackageSkeletons mt={'32px'} /> :
                            <Box display={'flex'} flexDirection={'column'} style={{ gap: '32px' }} mt={'32px'}>
                                {currentPackageActivities
                                    .map((item) => (<ActivityCard
                                        key={item.id}
                                        packageName={item}
                                        onChangeActivity={onChangeActivity}
                                        setDuration={setDuration}
                                        timeButtonsGroups={timeButtonsGroups}
                                        slots={Number(storedActivity?.slots)}
                                        isShowMoreInfo={selectActivityId === item.id}
                                        toggleShowMoreInfo={() => handleToggleShowMoreInfo(item.id)}
                                        isLessTreeTimeSlots={false}
                                        error={error}
                                    />
                                    ))}
                            </Box>}
                    </Box>
                    {isHavingRequiredActivities ?
                        <Box mt={'22px'} mb={'24px'}>
                            <Typography className='root bold required-tag'><span className='required-star'>*</span>Required Activity</Typography>
                        </Box> : <Box height={'24px'} />}
                    {(showRequiredError && <Box my={'24px'}>
                        <NoAvailiableGuests
                            text='Select all required activities'
                            noSpace
                        /></Box>)}
                    {isMobile ? (
                        <MobileAttachedButton
                            reservation={reservation}
                            onButtonClick={onContinue}
                            isButtonDisabled={isContinueDisabled}
                            giftCardAmount={giftCardAmount}
                            isUpdateReservation={isUpdateReservation}
                            venueId={activeVenue?.id} />
                    ) : (
                        uiConfig?.newDesign ? <ButtonMain onClick={onContinue} disabled={isContinueDisabled}>Continue</ButtonMain> : <PrimaryButton
                            onClick={onContinue}
                            name="continue"
                            uiConfig={uiConfig}
                            disabled={isContinueDisabled}
                        >
                            CONTINUE
                        </PrimaryButton>
                    )}
                </div>


                {!isMobile && (
                    <>
                        <div className="column-delimiter"></div>
                        <div className="reservation-summary">

                            <OrderSummary
                                reservation={reservation}
                                venue={activeVenue}
                                onEdit={backToReservation}
                                actionText={uiConfig?.actionText}
                                isMobile={isMobile}
                                isHideDuration={isHideDuration}
                                currentPackage={currentPackage}
                                giftCardAmount={giftCardAmount}
                                isUpdateReservation={isUpdateReservation}
                                oldDeposit={reservation?.payed}
                                reservationAddons={reservationAddons}
                                twelveHourClockFormat={twelveHourClockFormat}
                                notShowContinue
                                isLoading={isRequestInProgress}

                            />

                        </div>
                    </>
                )}
            </div>

        </div >
    );
};

export default connector(Activities);