import { Box, Checkbox, FormControlLabel, Theme, Typography, useTheme } from '@material-ui/core';
import { flatten, map } from 'lodash';
import { CompoundButton, IButtonStyles } from 'office-ui-fabric-react';
import React from 'react';
import { formatPrice, rem } from '../../../../../common/utils/formats';
import { VenueDto } from '../../../../../server/src/dto/venue.dto';
import { CurrencyType, PackageName, Pricing } from '../../../store/types';
import { TimeButtonType } from '../../MakePackageReservation/MakePackageReservation';
import TimeButtonsSkeletons from '../../PackageCard/TimeButtonsSkeletons';
import CustomSwiper from '../Swiper/CustomSwiper';
import TimeCardLoader from './TimeCardLoader';
import BootstrapTooltip from '../../PackageCard/tooltip';
import { AgeGroupPricing } from '../../../../../server/src/utils/ageGroups';
import { AgeGroup } from '../../../../../server/src/dto/ageGroup.dto';
function ageGroupToString({
    obj,
    ageGroups,
    currency,
}: {
    obj?: AgeGroupPricing;
    ageGroups: AgeGroup[];
    currency: CurrencyType;
}) {
    if (!obj) return '';
    return Object.entries(obj)
        .map(([key, value]) => `${ageGroups.find(ag => ag.groupName === key)?.abbreviation || key}: ${formatPrice(value.rate || 0, currency)}`)
        .join(' \n');
}
const renderTimeButtons = (
    timeButtonsGroups: any[][],
    setTimeSlot: (slot: number) => void,
    slots: number,
    getTimeButtonsClass: () => string,
    timeButtonStyleV2: (isPressed: boolean, theme: Theme, isTabletScreen: boolean) => any,
    renderPriceForTimeSlot: (t: TimeButtonType, isPressed: boolean) => React.ReactNode,
    isTabletScreen: boolean,
    theme: Theme,
    packageName: any,
    currency: CurrencyType
) => {

    const isAgeGroupBased =
        packageName?.ageGroups &&
        (packageName?.method === Pricing.ageGroupsFlatRate ||
            packageName?.method === Pricing.ageGroupsPerHour);

    return map(timeButtonsGroups, (timeButtonsRow, indexRow, arr) => (
        <Box
            className={`timeButton-row timeButton-row-v2 ${indexRow === timeButtonsGroups.length - 1 ? 'no-padding' : ''
                }`}
            key={indexRow}
        >
            {map(timeButtonsRow, (t: TimeButtonType, indexColumn) => {
                const isPressed = t.slot === slots;
                return (

                    <React.Fragment key={t.slot}
                    >

                        <Box className={`timeButton ${getTimeButtonsClass()}`} key={t.slot}>
                            <BootstrapTooltip
                                // age group pricing is not defined
                                title={ageGroupToString({
                                    obj: t.ageGroupPricing,
                                    ageGroups: packageName.ageGroups,
                                    currency,
                                }) || ''}
                                placement="top"
                                isPressed={isPressed}
                                isOn={isAgeGroupBased}
                            >
                                <CompoundButton
                                    className="focusableButton"
                                    styles={timeButtonStyleV2(isPressed, theme, isTabletScreen)}
                                    onClick={() => setTimeSlot(t.slot)}
                                    name="timeAndPrice"
                                    data-testid={`timeButton-${packageName.name}-${indexRow * arr.length + indexColumn + 1
                                        }`}
                                >
                                    {t.time}
                                    {renderPriceForTimeSlot(t, isPressed)}
                                </CompoundButton>
                            </BootstrapTooltip>
                        </Box>
                        {indexColumn < timeButtonsRow.length - 1 && (
                            <Box className="button-delimiter"></Box>
                        )}
                    </React.Fragment>
                );
            })}
        </Box>
    ));
};
export function transposeTimeSlots(timeSlots: any[]) {
    return timeSlots.map((group, groupIndex, groupsArray) => {
        // Find the longest inner array to determine the new structure's depth
        const maxLength = Math.max(...group.map((slot: any) => slot.length));

        // Initialize a new array structure based on the maximum length found
        const transposed = Array.from({ length: maxLength }, () => []);

        // Fill the new structure, transposing the elements
        group.forEach((row: any, rowIndex: number) => {
            row.forEach((item: any, columnIndex: number) => {
                // @ts-ignore
                transposed[columnIndex][rowIndex] = item;
            });
        });

        if (groupIndex === groupsArray.length - 1 && transposed.length === 3 && transposed.every(arr => arr.length === 1)) {
            return [
                [transposed[0][0], transposed[1][0]],
                [transposed[2][0]]
            ];
        }

        if (groupIndex === groupsArray.length - 1 && transposed.length === 2 && transposed.every(arr => arr.length === 1)) {
            return [
                [transposed[0][0], transposed[1][0]]
            ]
        }

        return transposed;
    });
}


function separateArrayIntoChunks(
    arr: any[],
    chunkSize: number,
    isFull?: boolean
) {
    // use lodash
    return flatten(
        arr.map((_, i) =>
            i % chunkSize === 0
                ? [arr.slice(i, i + chunkSize)]
                : []
        )
    );
}

export const timeButtonStyleV2 = (isPressed: boolean, theme: Theme, isTablet?: boolean): IButtonStyles => ({
    root: {
        width: "100%",
        margin: 0,
        height: isTablet ? rem(48) : rem(64),
        maxWidth: "100%",
        minHeight: isTablet ? rem(48) : rem(64),
        padding: "12px 24px",
        background: isPressed ? '#212B36' : theme.palette.secondary.main,
        color: isPressed ? 'white' : "black",
        fontWeight: "bold",
        border: isPressed ? `1px solid #212B36` : `1px solid ${theme.palette.secondary.main}`,
        fontSize: rem(14),
        lineHeight: '24px',
        transition: 'all .05s ease',
        position: 'relative',
        borderRadius: '4px',
        overflow: 'hidden',
    },
    rootHovered: {
        borderColor: '#212B36',
        color: isPressed ? 'white' : 'black',
        background: isPressed ? '#212B36' : theme.palette.secondary.main,
    },
    flexContainer: {
        flexDirection: "column",
        alignItems: "center",
    },
    textContainer: {
        textAlign: "center",
    },
});

interface Props {
    isLoadingVenue: boolean;
    isMobile: boolean;
    isTabletScreen: boolean;
    timeButtonsGroupsAll: any[];
    firstContainerWidth: number;
    isShowReservationTime: boolean;
    slots: number;
    setTimeSlot: (slot: number) => void;
    getTimeButtonsClass: () => string;
    renderPriceForTimeSlot: (t: TimeButtonType, isPressed: boolean) => React.ReactNode;
    packageName?: PackageName;
    show21CheckBox: boolean;
    isAgePolicyChecked: boolean;
    setIsAgePolicyChecked: (checked: boolean) => void;
    activeVenue?: VenueDto;
    currency: CurrencyType;
}
const TimeSelection = ({
    isLoadingVenue,
    isMobile,
    isTabletScreen,
    timeButtonsGroupsAll,
    firstContainerWidth,
    isShowReservationTime,
    slots,
    setTimeSlot,
    getTimeButtonsClass,
    renderPriceForTimeSlot,
    packageName,
    show21CheckBox,
    isAgePolicyChecked,
    setIsAgePolicyChecked,
    activeVenue,
    currency
}: Props) => {
    const theme = useTheme();
    if (isLoadingVenue) {
        return <TimeButtonsSkeletons />;
    }

    const timeButtonsGroups = isMobile
        ? transposeTimeSlots(separateArrayIntoChunks(timeButtonsGroupsAll, 2))
        : separateArrayIntoChunks(timeButtonsGroupsAll, 3);
    return (
        <>
            {isMobile ? (
                <TimeCardLoader
                    timeCards={map(timeButtonsGroups, (group, index) => (
                        <div className="time-buttons" key={index}>
                            {renderTimeButtons(
                                group,
                                setTimeSlot,
                                slots,
                                getTimeButtonsClass,
                                timeButtonStyleV2,
                                renderPriceForTimeSlot,
                                isTabletScreen,
                                theme,
                                packageName,
                                currency
                            )}
                        </div>
                    ))}
                />
            ) : (
                <CustomSwiper
                    width={firstContainerWidth}
                    showReservationTime={isShowReservationTime}
                    notShowButtons={timeButtonsGroups.length < 2}
                >
                    {map(timeButtonsGroups, (group, index) => (
                        <div className="time-buttons" key={index}>
                            {renderTimeButtons(
                                group,
                                setTimeSlot,
                                slots,
                                getTimeButtonsClass,
                                timeButtonStyleV2,
                                renderPriceForTimeSlot,
                                isTabletScreen,
                                theme,
                                packageName,
                                currency
                            )}
                        </div>
                    ))}
                </CustomSwiper>
            )}
            {show21CheckBox && (
                <FormControlLabel
                    style={{
                        marginTop: '24px',
                        alignItems: 'flex-start',
                    }}
                    control={
                        <Checkbox
                            checked={isAgePolicyChecked}
                            onChange={() => setIsAgePolicyChecked(!isAgePolicyChecked)}
                            name="checked"
                            color="default"
                            style={{
                                color: '#2E3A48',
                                top: '-5px',
                            }}
                        />
                    }
                    label={
                        <Typography className="body-small">{`Our venue is restricted to guests ${activeVenue?.ageForPolicy || '21'
                            }+ during this reservation time. Please check the box to confirm your party will not have any guests under ${activeVenue?.ageForPolicy || '21'
                            }.`}</Typography>
                    }
                />
            )}
        </>
    );
};

export default TimeSelection;
