import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { getSearch, replace } from "connected-react-router";

import { CompoundButton, IButtonStyles } from "office-ui-fabric-react";
import React, {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ConnectedProps, connect } from "react-redux";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import {
  formatDuration,
  formatDurationRange,
  formatPrice,
  formatPriceBasedOnValue,
  getPackageAssignedDuration,
  getPackageDurations,
  rem,
} from "../../../../common/utils/formats";
import { GuestsWithAgeGroup } from "../../../../server/src/dto/guestsWithAgeGroup";
import { AgeGroupPricing } from "../../../../server/src/utils/ageGroups";
import AccountIcon from "../../../assets/account-icon.svgr";
import defaultImage from "../../../assets/default-image.jpg";
import InfoIcon from "../../../assets/info.svgr";
import InfoWhiteIcon from "../../../assets/info-white.svgr";
import PlusIcon from "../../../assets/plus-icon.svg";
import PlusTabletIcon from "../../../assets/plus-tablet-icon.svg";
import TimeIcon from "../../../assets/time.svgr";
import {
  pushUrlPathAction,
  setReservationErrorAction,
  startBookingAction,
  updateReservationAction,
} from "../../actions/reservation-actions";
import {
  changeVenuePackageAction,
  changeVenuePackageWithReservationAction,
  toPartyWithVenueAction,
} from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import { selectAuthenticated, selectClient } from "../../reducers/auth-reducer";
import {
  selectIsUpdateReservation,
  selectReservation,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
  selectAvailability,
  selectCurrentPackage,
  selectIsLoadingVenue,
  selectVenue,
} from "../../reducers/venues";
import {
  Availability,
  CurrencyType,
  PackageName,
  Pricing,
  State,
} from "../../store/types";
import { TimeButtonType } from "../MakePackageReservation/MakePackageReservation";
import { formatDate } from "../MakePackageReservation/utils";
import MemberSignInButton from "../Memberships/MemberSignInButton";
import ButtonMain from "../common/ButtonMain/ButtonMain";
import { Calendar } from "../common/Calendar/Calendar";
import { mobileDropshodow } from "../common/MobileAttachedButton/utils";
import SelectGuestAge from "../common/SelectWithIcon/SelectGuestAge";
import SelectWithIcon from "../common/SelectWithIcon/SelectWithIcon";
import SwipeableBottomDrawer from "../common/SwipeableDrawer/SwipeableBottomDrawer";
import CustomSwiper from "../common/Swiper/CustomSwiper";
import NoAvailiableGuests from "./NoAvailiableGuests";
import TimeButtonsSkeletons from "./TimeButtonsSkeletons";
import "./packageCard.scss";
import BootstrapTooltip from "./tooltip";
import _ from "lodash";
import CloseIcon from "../../../assets/close.svgr";
import TimeSelection from "../common/TimeCardLoader/TimeSelection";
import { BookingStep } from "../../utils/steps";
import { getIsMemberPlanAvailableForPackage } from "../../utils/clients";

const mapDispatchToProps = {
  changeVenuePackage: changeVenuePackageAction,
  updateReservation: updateReservationAction,
  changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
  toPartyWithVenue: toPartyWithVenueAction,
  startBooking: startBookingAction,
  replace: replace,
  pushUrlPath: pushUrlPathAction,
  setReservationError: setReservationErrorAction,
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  uiConfig: selectUIConfig(state),
  currentPackage: selectCurrentPackage(state),
  activeVenue: selectVenue(state),
  availability: selectAvailability(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  searchParams: getSearch(state),
  authenticated: selectAuthenticated(state),
  client: selectClient(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = {
  packageName: PackageName;
  onChangeCurrentPackage: (packageName: PackageName) => void;
  setDuration: (packageName: PackageName, duration: number | null) => void;
  onContactUs: (e: React.FormEvent) => void;
  timeButtonsGroups: TimeButtonType[][];
  availability: Availability;
  slots: number;
  setErrorGuestCount: (value: React.SetStateAction<string>) => void;
  toggleShowMoreInfo: () => void;
  isShowMoreInfo: boolean;
  isLessTreeTimeSlots: boolean;
  error?: string;
  dataTestId?: string;
} & ConnectedProps<typeof connector>;

function separateArrayIntoChunks(
  arr: any[],
  chunkSize: number,
  isFull?: boolean
) {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}
const getMenuPackagePropsGuests2: (
  minGuests: number,
  maxGuests: number,
  includeAdditionalOption?: boolean
) => string[] = (
  minGuests: number,
  maxGuests: number,
  includeAdditionalOption?: boolean
) => {
    const start = minGuests;
    const count = maxGuests - start + 1;

    const result = Array.from({ length: count }, (_, index) => {
      const value = start + index;
      return value.toString();
    });

    // Conditionally add an additional option if the condition is true
    if (includeAdditionalOption) {
      result.push(maxGuests + 1 + "+");
    }

    return result;
  };

function getNumberOfGuests(queryString: string): number | null {
  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(queryString);

  // Get the 'guests' parameter
  const guests = params.get("guests");

  // Convert the guests value to a number and return it
  return guests ? parseInt(guests, 10) : null;
}

function isPackageSelected(queryString: string): boolean {
  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(queryString);

  const packageExist = params.get("currentPackage");

  return !!packageExist;
}
const PackageCard = ({
  packageName,
  currentPackage,
  uiConfig,
  reservation,
  onChangeCurrentPackage,
  activeVenue,
  setDuration,
  onContactUs,
  timeButtonsGroups: timeButtonsGroupsAll,
  slots,
  availability,
  changeVenuePackage,
  isUpdateReservation,
  updateReservation,
  changeVenuePackageWithReservation,
  toPartyWithVenue,
  startBooking,
  isLoadingVenue,
  setErrorGuestCount,
  toggleShowMoreInfo,
  isShowMoreInfo,
  isLessTreeTimeSlots,
  error,
  searchParams,
  dataTestId,
  replace,
  authenticated,
  pushUrlPath,
  client,
  setReservationError
}: Props) => {
  const getAgeBasedGroups = () => {
    if (isUpdateReservation && reservation.guestsWithAgeGroups && reservation.guestsWithAgeGroups.length > 0) {
      return reservation.guestsWithAgeGroups;
    }
    return packageName?.ageGroups?.map((group) => ({
      ageGroup: group.groupName,
      count: group.minQty,
    })) || [];
  }
  const [guestsWithAgeGroups, setGuestsWithAgeGroups] = useState<
    GuestsWithAgeGroup[]
  >(
    getAgeBasedGroups()
  );

  const [errorSelect, setErrorSelect] = useState<string>("");
  const MORE_THAN_11 = 100;
  const packageIsActive = currentPackage?.id === packageName.id;
  const contactUsButtonText = activeVenue?.contactUsButtonText;
  const currency = uiConfig?.currency || CurrencyType.USD;

  const [isLoadingContinue, setIsLoadingContinue] = useState(false);
  const { isMobile, isMiddleScreen, isTabletScreen } = useViewport();
  const menuPackagePropsGuest = getMenuPackagePropsGuests2(
    packageName?.minGuests || 0,
    packageName?.maxGuests || 10,
    !packageName?.hideInquiryRedirect
  );

  const laneOptions = packageName?.resourceOptions?.trim().split(",")
  const getDiabledLaneOptions = useCallback(() => {
    if (!laneOptions || !reservation.lanes || !packageName.countLanesByGuestChoose || !packageName.maxGuestsPerLane) return [];

    const disabledLaneOptions = laneOptions.filter(lane => {
      const limitLane = Math.ceil(reservation.guests / (packageName?.maxGuestsPerLane || 1));

      return parseInt(lane) < limitLane
    })

    return disabledLaneOptions
  }, [laneOptions, reservation.lanes])

  const numberOfGuestsFromSearch = getNumberOfGuests(searchParams);

  const ageGroups = packageName?.ageGroups || [];

  const selectGuestsOption = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setReservationError('')

    if (!event.target.value) {
      return;
    }
    if ((event.target.value as string).includes("+")) {
      toPartyWithVenue(date);
      return;
    }
    setErrorGuestCount("");
    const guests = Number(
      (event.target.value as number).toString().match(/\d+/)?.[0]
    );
    setGuestsCount(guests);

    if (guests === MORE_THAN_11) {
      toPartyWithVenue(date);
      return;
    }
    changeVenuePackage({
      updatePackage: packageName,
      venueId,
      date,
      guests,
      selectDuration: duration,
    });
  };
  const applyGuestsWithAgeGroup = (guests: GuestsWithAgeGroup[]) => {
    setErrorGuestCount("");
    setReservationError('')
    updateReservation({ guestsWithAgeGroups: guests })
    setGuestsWithAgeGroups(guests);
    const guestsCount = guests.reduce((acc, g) => acc + g.count, 0);
    setGuestsCount(guestsCount);

    changeVenuePackage({
      updatePackage: packageName,
      venueId,
      date,
      guests: guestsCount,
      selectDuration: duration,
      guestsWithAgeGroups: guests,
    });
  };
  let durationList: number[] = [];
  if (packageName.enableDurationChoice) {
    durationList = getPackageDurations(packageName);
  }
  let durationSlots = durationList.map((d) => {
    const textButton = formatDuration(d, activeVenue?.timeSlotDuration);
    return { key: d, text: textButton };
  });

  const renderPrice = () => {
    if (packageName.hidePrice) return null;

    const minPrice = packageName.price
      ? formatPriceBasedOnValue(+packageName.price, currency, true)
      : "";
    const discountedPrice = formatPriceBasedOnValue(packageName?.discountedPrice || 0, currency, true, true)
    const actionText = uiConfig?.actionText?.toLocaleLowerCase();

    let priceMethod;



    switch (packageName.method) {
      case Pricing.perPerson:
        priceMethod = `/person`
        break;

      case Pricing.perLane:
        priceMethod = `/hour`;
        break;

      case Pricing.flatRate:
        priceMethod = "";
        break;

      case Pricing.flatRatePerLane:
        priceMethod = `/${actionText || "lane"}`;
        break;

      case Pricing.flatRatePerHour:
        priceMethod = `/hour`;
        break;

      default:
        priceMethod = `/person`
    }

    if (packageName?.resourceName) {
      priceMethod = `/${packageName.resourceName}`;
    }

    if (packageName?.perPersonLabel) {
      priceMethod = `/${packageName.perPersonLabel}`;
    }

    return (
      !packageName.contactOnly && (
        <Typography className="package-card__title-v2 package-card__price-v2">
          {(packageName.enableDiscountedPrice && discountedPrice) ?
            <Box display={'flex'} style={{ gap: '8px' }}>
              <Typography className="package-card__title-v2 package-card__price-discounted">{discountedPrice}</Typography>
              <Typography className="package-card__title-v2 package-card__price-important">{minPrice}</Typography>
            </Box>
            : minPrice}
          {(!packageName.enableDiscountedPrice || !packageName.discountedPriceNote) && <span className="package-card__price-from">from</span>}
          <span className="package-card__price-method">{priceMethod}</span>
        </Typography>
      )
    );
  };

  const [isAgePolicyChecked, setIsAgePolicyChecked] = useState(false);
  const isDurationVisible =
    !uiConfig?.hideDuration &&
    !currentPackage?.hidePackageDuration &&
    packageName.enableDurationChoice;
  const isShowResourceQuantity = packageName?.showResourceQuantity
  const isShowReservationTime = uiConfig?.showReservationTime;

  useEffect(() => {
    if (
      currentPackage?.id === packageName.id &&
      !isShowMoreInfo &&
      !isLoadingVenue
    ) {
      toggleShowMoreInfo();
    }
  }, [currentPackage?.id]);

  const handleShowMoreInfo = () => {
    if (isLoadingVenue && isShowMoreInfo) {
      toggleShowMoreInfo();
      onChangeCurrentPackage(packageName);
    } else {
      toggleShowMoreInfo();

      if (isShowMoreInfo)
        setTimeout(() => {
          onChangeCurrentPackage(packageName);
        }, 400);
      else onChangeCurrentPackage(packageName);
    }

    // if (!isMobile && !(currentPackage?.id === packageName.id) && uiConfig?.scrollToTimeSlotAfterSelect) scrollToContainer()
  };
  const formatGuestRange = (minGuests: number, maxGuests: number) => {
    return minGuests === maxGuests
      ? `${minGuests} ${packageName.guestsLabel || 'Guest'}`
      : `${minGuests}-${maxGuests} ${packageName.guestsLabel || 'Guests'}`;
  };
  const onContinue = () => {
    setIsLoadingContinue(true);
    if (!activeVenue || !currentPackage) {
      return;
    }
    if (
      (guestsCount &&
        (guestsCount > currentPackage.maxGuests ||
          guestsCount < currentPackage.minGuests)) ||
      !guestsCount
    ) {
      setErrorGuestCount(
        "Please select the number of guests from the range of guests in the selected package."
      );
      setIsLoadingContinue(false);
      return;
    }

    startBooking({
      venue: activeVenue,
      date,
      currentStep: BookingStep.Selection,
      currentPackage,
    });
  };

  const renderDuration = () => {
    if (packageName?.hidePackageDuration) {
      return <></>;
    }
    let packageDuration = packageName.duration;

    if (packageName?.enableAssignDuration) {
      const { guests } = reservation;
      packageDuration = getPackageAssignedDuration(packageName, guests);
    }

    let content = (
      <span>
        {formatDuration(packageDuration, activeVenue?.timeSlotDuration)}
      </span>
    );

    if (packageName.enableDurationChoice) {
      const minimumDuration = Math.min(...durationList);
      const maximumDuration = Math.max(...durationList);

      const duration = formatDurationRange(
        [minimumDuration, maximumDuration],
        activeVenue?.timeSlotDuration
      );
      content = (
        <span>
          {isMobile || isTabletScreen
            ? duration
              .replace("Hour", "Hr")
              .replace(/(\d+)\s*-\s*(\d+)/g, "$1-$2")
            : duration}
        </span>
      );
    }

    return (
      <Box className="package-card__bottom">
        <TimeIcon />
        <Typography className="package-card__guests-v2 bold">
          {content}
        </Typography>
      </Box>
    );
  };


  const renderButton = () => {
    if ((packageName.isMemberOnlyPackage &&
      !getIsMemberPlanAvailableForPackage({ packageName, client })
    )) return <MemberSignInButton onClick={() => pushUrlPath(!authenticated ? "/reservation-info/account" : "/memberships")} />;

    if (packageName.contactOnly) {
      return (
        <Button
          className="package-card__select-button-v2 is-contact-us root bold"
          onClick={onContactUs}
        >
          {contactUsButtonText}
        </Button>
      );
    }

    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleShowMoreInfo();
        }}
        className={`btn-v2 ${isLoadingVenue ? "loading" : ""} ${isTabletScreen ? "tablet" : "desktop"
          }`}
        disabled={isLoadingVenue}
      >
        <img
          src={isTabletScreen ? PlusTabletIcon : PlusIcon}
          alt="PlusIcon"
          style={{
            transform: isShowMoreInfo ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Button>
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        <Box
          className={`package-card__image-container-v2 ${isMobile ? "mobile" : ""
            } ${isTabletScreen ? "tablet" : ""}`}
        >
          <img
            src={packageName.image || defaultImage}
            alt={packageName.name}
            className={`package-card__image-v2 ${isMobile ? "mobile" : ""}`}
            style={isShowMoreInfo ? { borderBottomLeftRadius: 0 } : {}}
          />
          {/*<span className='package-card__tag'>NEW</span>*/}
        </Box>
        <Box
          className={`package-card__content-v2 ${isMobile ? "mobile" : ""} ${isTabletScreen ? "tablet" : ""
            }`}
        >
          {packageName.enableDiscountedPrice && packageName?.discountedPriceNote ?
            <Box className={`package-card__discounted-price-note body-small-white ${isMobile ? "mobile" : ""}`}>
              {packageName.discountedPriceNote}
            </Box> : null}
          <Box mb={isMobile ? "12px" : 0}>
            <Box className="package-card__content-top-v2">
              <Typography className="package-card__title-v2 ellipsis">
                {packageName.name}
              </Typography>
              {renderPrice()}
            </Box>
            <Typography className="package-card__short-description-v2">
              {packageName.shortDescription}
            </Typography>
          </Box>
          <Box
            className={`package-card__actions-v2 ${durationSlots.length > 0 && !packageName.hidePackageDuration
              ? ""
              : "package-card__without-selector"
              }`}
          >
            <Box className="package-card__bottom-container">
              <Box className="package-card__bottom">
                <AccountIcon />
                <Typography className="package-card__guests-v2 bold">
                  {formatGuestRange(
                    packageName.minGuests,
                    packageName.maxGuests
                  )}
                </Typography>
              </Box>
              {renderDuration()}
            </Box>
            {renderButton()}
          </Box>
        </Box>
      </>
    );
  };
  const [isCalendar, setIsCalendar] = useState(false);
  const { date, venueId, guests, duration } = reservation;
  const [guestsCount, setGuestsCount] = useState<number | null>(guests);

  const durationCount = durationSlots.find((dur) => dur.key === duration)?.text;

  useEffect(() => {
    if (
      !packageName ||
      !numberOfGuestsFromSearch ||
      numberOfGuestsFromSearch > packageName.maxGuests ||
      numberOfGuestsFromSearch < packageName.minGuests
    ) {
      setGuestsCount(null);
    } else {
      if (!isUpdateReservation) setGuestsCount(numberOfGuestsFromSearch);
    }
  }, [numberOfGuestsFromSearch, packageName]);

  const selectDate = (date: string) => {
    setDay(date);
    setIsCalendar(!isCalendar);
  };

  const setDay = (date: string) => {
    setReservationError('')

    if (isUpdateReservation) {
      updateReservation({ slots: undefined });
      changeVenuePackageWithReservation({
        updatePackage: currentPackage,
        date,
      });
    } else {
      changeVenuePackage({
        updatePackage: currentPackage,
        venueId,
        date,
        guests,
        guestsWithAgeGroups
      });
    }
  };
  const setTimeSlot = (slot: number) => {
    setReservationError('')
    updateReservation({ slots: slot });
  };

  const noAvailiableGuests =
    timeButtonsGroupsAll.length === 0 && !isLoadingVenue;
  const accordionDivRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const isInitialLoadSlots = timeButtonsGroupsAll.length === 0 && isLoadingVenue && isMobile;
  function scrollToContainer(ref?: RefObject<HTMLDivElement>) {
    const cardElement = ref?.current || cardRef.current;
    if (!cardElement) return;

    cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  let show21CheckBox = false;
  if (activeVenue?.timeSlots) {
    const slot = activeVenue.timeSlots.find((s: any) => s.time === slots);
    show21CheckBox = slot && !closed ? slot.is21plus : false;
  }
  const showDemo = uiConfig?.isDemo && !uiConfig.isHideDemoMessage;
  const showSandBoxPayment =
    !showDemo &&
    activeVenue?.sandBoxPayment &&
    !window.location.pathname.includes("select-location");

  const withDemo = showDemo || showSandBoxPayment;
  const packageCardOffset = "100px";

  const disableContinueButton =
    closed ||
    !venueId ||
    !activeVenue ||
    (show21CheckBox && !isAgePolicyChecked) ||
    !activeVenue.timeSlots.find((item) => item.time === slots) ||
    isNaN(reservation.slots) ||
    isLoadingVenue ||
    isLoadingContinue ||
    (!durationCount && isDurationVisible);
  const handleClickCalendar = () => {
    if (isMobile && !isCalendar) {
      scrollToContainer(calendarRef);
    }
    setIsCalendar(!isCalendar);
  };

  const updateAccordionHeight = () => {
    const contentDiv = accordionDivRef.current;

    if (contentDiv) {
      // Get all child elements inside the accordion
      const childElements = Array.from(contentDiv.children) as HTMLElement[];

      // Calculate total height including padding and margins
      const totalHeight = childElements.reduce(
        (acc, element) => acc + element.offsetHeight,
        0
      );

      accordionDivRef.current!.style!.height! = isShowMoreInfo
        ? `${totalHeight}px`
        : "0";
    }
  };
  useEffect(() => {
    if (!isMobile) {
      if (isShowMoreInfo) {
        if (
          !isMobile &&
          isPackageSelected(searchParams) &&
          !(currentPackage?.id === packageName.id) &&
          uiConfig?.scrollToTimeSlotAfterSelect
        ) {
          setTimeout(() => {
            scrollToContainer();
            updateAccordionHeight();
          }, 300);
        } else if (
          !isMobile &&
          !isPackageSelected(searchParams) &&
          !(currentPackage?.id === packageName.id) &&
          uiConfig?.scrollToTimeSlotAfterSelect
        ) {
          scrollToContainer();
          updateAccordionHeight();
        } else {
          updateAccordionHeight();
        }
      } else {
        accordionDivRef.current?.style.setProperty("height", "0");
      }
    }
  }, [packageIsActive, isCalendar, isShowMoreInfo]);

  useEffect(() => {
    if (!isMobile) {
      if (isShowMoreInfo) {
        updateAccordionHeight();
      } else {
        accordionDivRef.current?.style.setProperty("height", "0");
      }
    }
  }, [accordionDivRef.current?.innerHTML]);
  const firstContainerRef = useRef<HTMLDivElement>(null);
  const [firstContainerWidth, setFirstContainerWidth] = useState<number>(0);
  const updateFirstContainerWidth = () => {
    const firstContainer = firstContainerRef.current;
    if (firstContainer) {
      setFirstContainerWidth(firstContainer.clientWidth);
    }
  };
  useEffect(() => {
    updateFirstContainerWidth();

    window.addEventListener("resize", updateFirstContainerWidth);
    return () => {
      window.removeEventListener("resize", updateFirstContainerWidth);
    };
  }, []);
  const getTimeButtonsClass = () => {
    let timeButtonClass = "";
    if (isLessTreeTimeSlots) {
      timeButtonClass = "fullWidth";
    } else if (!isShowReservationTime) {
      if (isMiddleScreen) {
        timeButtonClass = isMobile ? "mobile" : "";
      }
    } else {
      timeButtonClass = isMobile ? "small" : "middle";
    }
    return timeButtonClass;
  };
  const isAgeGroupBased =
    packageName?.ageGroups &&
    (packageName?.method === Pricing.ageGroupsFlatRate ||
      packageName?.method === Pricing.ageGroupsPerHour);

  const handleModifyLanes = (lanes?: string) => {
    if (!lanes) {
      return;
    }
    const lanesCount = parseInt(lanes, 10);
    updateReservation({ lanes: lanesCount });
  }

  const renderPriceForTimeSlot = (t: TimeButtonType, isPressed: boolean) => {
    if (packageName.hidePriceOnTimeCard) return null;

    return <Box
      className={`price2 ${isTabletScreen ? "tablet" : ""
        } ${isPressed ? "pressed" : ""}`}
    >
      {/* {index === 0 && <span className="price2-sale">{'$49.00'}</span>} */}
      {isAgeGroupBased ? (
        <Box
          display={"flex"}
          style={{ gap: "4px" }}
          alignItems={"center"}
        >
          <Typography className={`${isPressed ? 'body-small-white' : 'body-small'}`}>
            See Prices
          </Typography>

          <Box display={"flex"}>
            {isPressed ? <InfoWhiteIcon /> : <InfoIcon />}
          </Box>
        </Box>


      ) : (
        t.price
      )}
    </Box>
  }

  const renderActiveView = () => {
    return (
      <div
        ref={accordionDivRef}
        style={{
          transition: "height 0.3s ease-in-out",
          overflowY: isMobile ? "auto" : "hidden",
          overflowX: "hidden",
        }}
      >
        {isMobile && <Button className="package-close-btn" onClick={handleShowMoreInfo}>
          <CloseIcon />
        </Button>}
        <Box
          className={`package-card__info-content-container-v2 ${isMobile ? "mobile" : ""
            } ${isTabletScreen ? "tablet" : ""}`}
          style={
            isMobile && !noAvailiableGuests ? { paddingBottom: "90px" } : {}
          }
          data-testid={"package-containers"}
        >

          {isMobile ? (
            <Box
              className="package-card__content-v2"
              p={0}
              pb={"32px"}
              mb={"32px"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.05)"}
            >
              {packageName.enableDiscountedPrice && packageName?.discountedPriceNote ?
                <Box className={`package-card__discounted-price-note body-small-white ${isMobile ? "mobile" : ""}`}>
                  {packageName.discountedPriceNote}
                </Box> : null}
              <Box mb={0}>
                <Box className="package-card__content-top-v2">
                  <Typography className="package-card__title-v2 ellipsis">
                    {packageName.name}
                  </Typography>
                  {renderPrice()}
                </Box>
                <Typography
                  className="package-card__short-description-v2"
                  style={{ marginBottom: "20px" }}
                >
                  {packageName.shortDescription}
                </Typography>
              </Box>
              <Box
                className={`package-card__actions-v2 ${durationSlots.length > 0 && !packageName.hidePackageDuration
                  ? ""
                  : "package-card__without-selector"
                  }`}
              >
                <Box className="package-card__bottom-container">
                  <Box className="package-card__bottom">
                    <AccountIcon />
                    <Typography className="package-card__guests-v2 bold">
                      {packageName.minGuests}-{packageName.maxGuests}
                    </Typography>
                  </Box>
                  {renderDuration()}
                </Box>
              </Box>
            </Box>
          ) : null}

          <HTMLRenderer
            className="package-card__info-content-v2 overflow-auto break-word"
            html={packageName.description}
            withExtractInnerTags
          />

          <Box>
            <Typography className="heading-h6 package-card__info-title">
              {packageName.reservationDetails || "Reservation Details"}
            </Typography>
            <div ref={firstContainerRef}>
              <Box
                className={`package-card__info-row package-card__info-content-container ${isMobile ? "mobile" : ""
                  }`}
              >
                <SelectWithIcon
                  width={
                    isMobile
                      ? "100%"
                      : isDurationVisible
                        ? "calc(50% - 8px)"
                        : "50%"
                  }
                  label="Select Date"
                  options={[
                    formatDate(date, isMobile || isTabletScreen) || "Loading the date...",
                  ]}
                  onFormClick={handleClickCalendar}
                  onClick={handleClickCalendar}
                />
                {isMobile && <div ref={calendarRef} style={{ scrollMarginTop: 0 }} />}
                {isCalendar && activeVenue?.availability && isMobile ? (
                  <Calendar
                    date={date}
                    selectDate={selectDate}
                    availability={activeVenue?.availability}
                    isMiddleScreen={isMiddleScreen}
                    isInsideContainer
                  />
                ) : null}
                {isAgeGroupBased ? (
                  <SelectGuestAge
                    options={ageGroups}
                    label={`${packageName.guestsLabel || "Guests"}`}
                    guestsWithAgeGroups={guestsWithAgeGroups}
                    applyGuestsWithAgeGroup={applyGuestsWithAgeGroup}
                    errorSelect={errorSelect}
                    width={
                      isMobile
                        ? "100%" :
                        (!isShowResourceQuantity && !isDurationVisible) ?
                          'calc(50% - 8px)' :
                          (!isShowResourceQuantity && isDurationVisible)
                            ? "calc(25% - 4px)"
                            : isDurationVisible
                              ? "calc(50% - 8px)"
                              : "50%"
                    }
                    isDurationVisible={isDurationVisible}
                    truncateLabel
                    minPackageGuests={packageName.minGuests}
                    maxPackageGuests={packageName.maxGuests}
                    currency={currency}
                    ageGroupsPricingNote={activeVenue?.ageGroupsPricingNote}
                  />
                ) : (
                  <SelectWithIcon
                    errorSelect={isMobile ? error : ""}
                    width={
                      isMobile
                        ? "100%" :
                        (!isShowResourceQuantity && !isDurationVisible) ?
                          'calc(50% - 8px)' :
                          (!isShowResourceQuantity && isDurationVisible)
                            ? "calc(25% - 4px)"
                            : isDurationVisible
                              ? "calc(50% - 8px)"
                              : "50%"
                    }
                    label={`${packageName.guestsLabel || "Guests"}`}
                    options={menuPackagePropsGuest}
                    value={guestsCount}
                    onChange={selectGuestsOption}
                  />
                )}
                {isDurationVisible ? (
                  <SelectWithIcon
                    width={isMobile ? "100%" : !isShowResourceQuantity ? "calc(25% - 4px)" : "calc(50% - 8px)"}
                    label="Duration"
                    options={durationSlots.map((dur) => dur.text)}
                    value={
                      durationCount ||
                      (durationSlots &&
                        durationSlots.length > 0 &&
                        durationSlots[0].text)
                    }
                    onChange={(e) =>
                      setDuration(
                        packageName,
                        durationSlots.find((dur) => dur.text === e.target.value)?.key || 0
                      )
                    }
                  />
                ) : null}
                {isShowResourceQuantity ? (
                  <SelectWithIcon
                    width={isMobile ? "100%" : "calc(50% - 8px)"}
                    label={(packageName?.resourceName || uiConfig?.actionText || "Lane") + "s"}
                    options={laneOptions}
                    disabledOptions={getDiabledLaneOptions()}
                    value={reservation?.lanes}
                    onChange={(e) => handleModifyLanes(e.target.value as string)}
                  />
                ) : null}
              </Box>

            </div>
            {isCalendar && activeVenue?.availability && !isMobile ? (
              <Calendar
                date={date}
                selectDate={selectDate}
                availability={activeVenue?.availability}
                isMiddleScreen={isMiddleScreen}
                isInsideContainer
              />
            ) : null}
            <Box
              className={
                noAvailiableGuests
                  ? ""
                  : "package-card__info-content-container-time"
              }
            >
              {(timeButtonsGroupsAll.length &&
                timeButtonsGroupsAll.length !== 0) ||
                isLoadingVenue ? (
                <Typography className="heading-h6 package-card__info-title">
                  {packageName?.selectTime || "Select Time"}
                </Typography>
              ) : null}
              <TimeSelection
                timeButtonsGroupsAll={timeButtonsGroupsAll}
                isMobile={isMobile}
                isTabletScreen={isTabletScreen}
                isShowReservationTime={isShowReservationTime || false}
                getTimeButtonsClass={getTimeButtonsClass}
                renderPriceForTimeSlot={renderPriceForTimeSlot}
                setTimeSlot={setTimeSlot}
                activeVenue={activeVenue}
                firstContainerWidth={firstContainerWidth}
                isAgePolicyChecked={isAgePolicyChecked}
                setIsAgePolicyChecked={setIsAgePolicyChecked}
                isLoadingVenue={isLoadingVenue}
                packageName={currentPackage}
                show21CheckBox={show21CheckBox}
                slots={slots}
                currency={currency}
              />
            </Box>
            {noAvailiableGuests && (
              <NoAvailiableGuests
                noSpace
                text={
                  activeVenue?.inactivePackageMessage ? (
                    <HTMLRenderer html={activeVenue?.inactivePackageMessage} />
                  ) : (
                    ""
                  )
                }
              />
            )}

            {error && !isMobile && (
              <Box
                className={`error ${isMobile ? "package-card-2__error_mobile" : ""
                  }`}
              >
                {error}
              </Box>
            )}

            {(noAvailiableGuests || isInitialLoadSlots) ? null : (
              <>
                <Box height={"12px"} />
                {(isMobile) ? (
                  (<Box
                    position={"fixed"}
                    width={"100%"}
                    p={"24px"}
                    bottom={0}
                    left={0}
                    bgcolor={"white"}
                    zIndex={1000}
                    style={{ filter: mobileDropshodow }}
                  >
                    <ButtonMain
                      disabled={disableContinueButton}
                      onClick={onContinue}
                    >
                      Continue
                    </ButtonMain>
                  </Box>)
                ) : (
                  <ButtonMain
                    disabled={disableContinueButton}
                    onClick={onContinue}
                  >
                    Continue
                  </ButtonMain>
                )}
              </>
            )}
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <>
      <article
        className={classNames("package-card-v2", {
          "is-active": packageIsActive,
          "is-mobile": isMiddleScreen,
        })}
        style={{
          scrollMarginTop: packageCardOffset,
          cursor: !isShowMoreInfo ? "pointer" : "default",
        }}
        onClick={
          (packageName.isMemberOnlyPackage && !getIsMemberPlanAvailableForPackage({ packageName, client }))
            ? () => pushUrlPath(authenticated ? "/memberships" : "/reservation-info/account")
            : packageName.contactOnly
              ? onContactUs
              : !isShowMoreInfo
                ? handleShowMoreInfo
                : () => { }
        }
        ref={cardRef}
      >
        <Box
          className={`package-card-v2-inside ${isMobile ? "mobile" : ""}`}
          data-testid={dataTestId || "package-cards"}
        >
          {renderDesktopView()}
        </Box>
        {!isMobile ? (
          renderActiveView()
        ) : (
          <SwipeableBottomDrawer
            open={isShowMoreInfo}
            onClose={handleShowMoreInfo}
          >

            {renderActiveView()}
          </SwipeableBottomDrawer>
        )}
      </article>
    </>
  );
};

export default connector(PackageCard);
