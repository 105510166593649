import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Select,
  SelectProps,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import MinusIcon from "../../../../assets/minus-lg.svgr";
import PlusIcon from "../../../../assets/plus-lg.svgr";
import SelectIcon from "../../../../assets/select-icon.svg";
import { useViewport } from "../../../hooks/responsive";
import { useSelectWithIconStyles } from "./SelectWithIcon";
import ButtonMain from "../ButtonMain/ButtonMain";
import { GuestsWithAgeGroup } from "../../../../../server/src/dto/guestsWithAgeGroup";
import { AgeGroup } from "../../../../../server/src/dto/ageGroup.dto";
import { formatPrice } from "../../../../../common/utils/formats";
import { CurrencyType } from "../../../store/types";
interface Props extends SelectProps {
  optionTitle?: string;
  options?: AgeGroup[];
  label?: string;
  width?: string;
  onFormClick?: () => void;
  truncateLabel?: boolean;
  errorSelect?: string;
  guestsWithAgeGroups: GuestsWithAgeGroup[];
  applyGuestsWithAgeGroup: (guests: GuestsWithAgeGroup[]) => void;
  isDurationVisible?: boolean;
  maxPackageGuests: number;
  minPackageGuests: number;
  currency?: CurrencyType;
  ageGroupsPricingNote?: string;
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    borderRadius: "4px",
    backgroundColor: "#EBECF0",
    "&.Mui-disabled": {
      opacity: 0.3,
      border: "1px solid",
    },
    height: "44px",
    width: "44px",


  },
  mobileHover: {
    '&:hover': {
      backgroundColor: "#EBECF0 !important",
    }
  },
  menuCalc: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  menu: {
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow:
      "0px 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 8px 8px -4px rgba(0, 0, 0, 0.03)",
    padding: 0,
    paddingTop: "12px",
  },
  applyButton: {
    height: "40px",
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuItemMobile: {
    width: "inherit",

  },
  title: {
    maxWidth: "200px",
  },
  label: {
    marginLeft: "24px",
  },
  tabletLabel: {
    marginTop: "-7px",
  },
}));

const SelectGuestAge = ({
  options,
  optionTitle,
  label,
  width,
  onFormClick,
  truncateLabel,
  errorSelect,
  guestsWithAgeGroups,
  applyGuestsWithAgeGroup,
  isDurationVisible,
  maxPackageGuests,
  minPackageGuests,
  currency,
  ageGroupsPricingNote,
  ...props
}: Props) => {
  const getSelectedValue = () => {
    const selectedStrings = options
      ?.map((option, index) => {
        const guestWithAge = guests.find(g => g.ageGroup === option.groupName);
        return `${guestWithAge ? guestWithAge.count : 0} ${option.abbreviation}`;
      })
      .join(", ");

    return selectedStrings;
  }

  const selectClasses = useSelectWithIconStyles();
  const classes = useStyles();
  const { isMobile, isTabletScreen } = useViewport();
  const [guests, setGuests] = useState<GuestsWithAgeGroup[]>(guestsWithAgeGroups);
  const [error, setError] = useState<string | null>(null);

  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(getSelectedValue() || '');

  const handleFormClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onFormClick) {
      onFormClick();
      event.stopPropagation();
    } else {
      if (selectRef.current) {
        setOpen(!open);
      }
    }
  };

  const handleClose = () => {
    if (!error) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const formControlRef = useRef<HTMLDivElement>(null);
  const [widthOfFormControl, setWidthOfFormControl] = useState(0);

  useEffect(() => {
    if (formControlRef.current) {
      const rect = formControlRef.current.getBoundingClientRect();
      setWidthOfFormControl(rect.width);
    }
  }, [formControlRef.current]);


  const handleIncrement = (index: number, maxGuests: number) => {
    setError(null);

    setGuests(prev => {
      const totalGuests = prev.reduce((sum, item) => sum + item.count, 0);
      const newTotal = totalGuests + 1;

      if (newTotal > maxPackageGuests) {
        return prev; // Do not increment if it exceeds maxPackageGuests
      }

      return prev.map((item, i) =>
        i === index ? { ...item, count: Math.min(item.count + 1, maxGuests) } : item
      );
    });
  };

  const handleDecrement = (index: number, minGuests: number) => {
    setError(null);

    setGuests(prev => {
      return prev.map((item, i) =>
        i === index ? { ...item, count: Math.max(item.count - 1, minGuests) } : item
      );
    });
  };

  const handleQuantityChange = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleApply = (event: React.MouseEvent) => {
    event.stopPropagation();

    const totalGuests = guests.reduce((sum, item) => sum + item.count, 0);
    if (totalGuests > maxPackageGuests) {
      setError(`Guest count must not exceed ${maxPackageGuests}`);

    } else if (totalGuests < minPackageGuests) {
      setError(`Guest count must be at least ${minPackageGuests}`);

    }
    else {
      setError(null);
      const selectedStrings = getSelectedValue();

      applyGuestsWithAgeGroup(guests);
      setSelectedValue(selectedStrings as string);
      setOpen(false);
    }

  };


  const totalGuests = guests.reduce((sum, item) => sum + item.count, 0);

  const renderPrice = (option: AgeGroup, currency?: CurrencyType) => {
    if (!option.minPrice) return null;

    const minPrice = formatPrice(option.minPrice, currency || CurrencyType.USD, false);

    if (option.maxPrice && option.maxPrice !== option.minPrice) {
      const maxPrice = formatPrice(option.maxPrice, currency || CurrencyType.USD, false);
      return `${minPrice} - ${maxPrice}`;
    }
    return minPrice;
  }

  return (
    <>
      <FormControl
        innerRef={formControlRef}
        className={`${selectClasses.form} ${selectClasses.formHover} v2`}
        style={{
          padding: "11px 24px",
          width,
          cursor: "pointer",
          maxHeight: isTabletScreen ? "48px" : "70px",
          border: errorSelect ? "1px solid #EE4C1A" : "1px solid #F6F7FA",
        }}
        onClick={handleFormClick}
        focused={!!onFormClick}
      >
        {label && (
          <InputLabel
            className={`${selectClasses.inputLabel}  ${truncateLabel ? selectClasses.truncateLabel : ""
              } ${errorSelect ? "error-label" : ""} ${classes.label} ${isTabletScreen ? classes.tabletLabel : ""
              }`}
            classes={{
              focused: isTabletScreen
                ? selectClasses.focusedTablet
                : selectClasses.focused,
              shrink: isTabletScreen
                ? selectClasses.focusedTablet
                : selectClasses.focused,
            }}
            variant="standard"
            htmlFor="uncontrolled-native"
          >
            {label}
          </InputLabel>
        )}
        <Select
          native={onFormClick ? true : false}
          disableUnderline
          inputRef={selectRef}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onBlur={handleClose}
          onSelect={handleClose}
          inputProps={{
            style: {
              marginLeft: 0,
              backgroundColor: "transparent",
              marginTop: 0,
              paddingBottom: "2px",
              fontSize: "16px",
              fontWeight: 500,
              color: "#212B36",
            },
            onBlur: handleClose,
            onChange: handleClose,
            onClick: handleClose,
          }}
          onClick={(e) => (onFormClick ? e.stopPropagation() : null)}
          onMouseDown={(e) => (onFormClick ? e.preventDefault() : null)}
          className={selectClasses.select}
          value={selectedValue}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: {
              paper: `${selectClasses.menu} ${classes.menu}  ${isMobile && selectClasses.menuMobile
                }`,
              list: selectClasses.menuList,
            },
            PaperProps: {
              style: {
                minWidth: `${widthOfFormControl}px`,
              },
            },
          }}
          renderValue={() => selectedValue}
          IconComponent={() => (
            <img
              src={SelectIcon}
              alt="SelectIcon"
              style={
                isTabletScreen
                  ? { marginBottom: "16px" }
                  : { marginBottom: "12px" }
              }
            />
          )}
          {...props}
        >
          {options?.map((option, index) => {
            const guestWithAge = guests.find(
              (g) => g.ageGroup === option.groupName
            );
            const count = guestWithAge ? guestWithAge.count : 0;

            return (
              <li
                key={index}
                value={option.groupName}
                className={`${selectClasses.menuItemAge} ${classes.menuItem} ${isMobile ? classes.menuItemMobile : ''} ${isDurationVisible ? selectClasses.menuWithDuration : ""
                  }`}
                onClick={handleQuantityChange}
              >
                <Box display={"flex"} alignItems={"center"}>
                  {props.value === option && (
                    <Check
                      style={{
                        position: "absolute",
                        left: 5,
                        top: "32%",
                        transform: "translateY(-50%)",
                        fontSize: "16px",
                      }}
                    />
                  )}
                  <Box>
                    <Typography
                      className={`root-large truncate-2-lines ${classes.title}`}
                    >
                      {option.groupName}
                    </Typography>
                    {renderPrice(option, currency)}
                  </Box>
                </Box>
                <Box
                  className={classes.menuCalc}
                  onClick={handleQuantityChange}
                >
                  <IconButton
                    size="medium"
                    onClick={() => handleDecrement(index, option.minQty)}
                    disabled={count <= (option.minQty || 0)}
                    className={`${classes.iconButton} ${isMobile ? classes.mobileHover : ''}`}
                  >
                    <MinusIcon />
                  </IconButton>
                  <Box width={'24px'} textAlign={'center'}>
                    <Typography className="root-text">{count}</Typography>
                  </Box>
                  <IconButton
                    size="medium"
                    onClick={() => handleIncrement(index, option.maxQty)}
                    disabled={count >= (option.maxQty || 0) || totalGuests >= maxPackageGuests}
                    className={`${classes.iconButton} ${isMobile ? classes.mobileHover : ''}`}
                  >
                    <PlusIcon />
                  </IconButton>
                </Box>
              </li>
            );
          })}
          <Box px={"20px"} mb={"33px"} onClick={handleQuantityChange}>
            {error && (
              <Box mt={"8px"}>
                <Typography className="select-error">
                  {error}
                </Typography>
              </Box>

            )}
            <Box mt={"8px"} mb={"27px"} maxWidth={'300px'}>
              <Typography className="body-small italic">
                {ageGroupsPricingNote || 'Prices may vary based on time selection.'}
              </Typography>
            </Box>
            <ButtonMain
              fullWidth
              onClick={handleApply}
              buttonClassName={classes.applyButton}
            >
              Apply
            </ButtonMain>
          </Box>
        </Select>
      </FormControl>
    </>
  );
};

export default SelectGuestAge;
