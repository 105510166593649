import { PackageNameDto } from "./packageName.dto";
import { ReservationDto } from "./reservation.dto";
import { ReservationAddonDto } from "./reservationAddon.dto";
import { VenueDto } from "./venue.dto";

export class SquareLocationDto {
  id: string;
  name: string | null;
  timezone?: string | null;
  capabilities?: string[];
  status?: string;
  createdAt?: string;
  merchantId?: string;
  country?: string;
  languageCode?: string | null;
  currency?: string;
  phoneNumber?: string | null;
  businessName?: string | null;
  type?: string;
  websiteUrl?: string | null;
  businessEmail?: string | null;
  description?: string | null;
  twitterUsername?: string | null;
  instagramUsername?: string | null;
  facebookUrl?: string | null;
  logoUrl?: string;
  posBackgroundUrl?: string;
  mcc?: string | null;
  fullFormatLogoUrl?: string;
}

interface CatalogV1Id {
  catalogV1Id?: string | null;
  locationId?: string | null;
}
interface CatalogCustomAttributeValue {
  name?: string | null;
  stringValue?: string | null;
  customAttributeDefinitionId?: string;
  type?: string;
  numberValue?: string | null;
  booleanValue?: boolean | null;
  selectionUidValues?: string[] | null;
  key?: string;
}
export class SquareCatalogDto {
  type: string;
  id: string;
  name: string;
  updatedAt?: string;
  isDeleted?: boolean | null;
  customAttributeValues?: Record<string, CatalogCustomAttributeValue> | null;
  catalogV1Ids?: CatalogV1Id[] | null;
  presentAtAllLocations?: boolean | null;
  presentAtLocationIds?: string[] | null;
  absentAtLocationIds?: string[] | null;
  // itemData?: CatalogItem; //1
  // categoryData?: CatalogCategory; //3
  // itemVariationData?: CatalogItemVariation; // 4
  // taxData?: CatalogTax; //5
  // discountData?: CatalogDiscount; //6
  // modifierListData?: CatalogModifierList; //7
  // modifierData?: CatalogModifier; //8
  // timePeriodData?: CatalogTimePeriod; //11
  // productSetData?: CatalogProductSet; //10
  // pricingRuleData?: CatalogPricingRule; //9
  // imageData?: CatalogImage; //2
  // measurementUnitData?: CatalogMeasurementUnit; //12
  // subscriptionPlanData?: CatalogSubscriptionPlan; //13
  // itemOptionData?: CatalogItemOption; //14
  // itemOptionValueData?: CatalogItemOptionValue; //15
  // customAttributeDefinitionData?: CatalogCustomAttributeDefinition; //16
  // quickAmountsSettingsData?: CatalogQuickAmountsSettings; //17
  // subscriptionPlanVariationData?: CatalogSubscriptionPlanVariation; //18
}

export class SquareItemDto {
  id: string;
  itemId: string;
  name: string;
  categoryName: string;
  price: string;
  tax: string;
}

const catalogTypes = [
  'ITEM',
  'IMAGE',
  'CATEGORY',
  'ITEM_VARIATION',
  'TAX',
  'DISCOUNT',
  'MODIFIER_LIST',
  'MODIFIER',
  'PRICING_RULE',
  'PRODUCT_SET',
  'TIME_PERIOD',
  'MEASUREMENT_UNIT',
  'SUBSCRIPTION_PLAN_VARIATION',
  'ITEM_OPTION',
  'ITEM_OPTION_VAL',
  'CUSTOM_ATTRIBUTE_DEFINITION',
  'QUICK_AMOUNTS_SETTINGS',
  'SUBSCRIPTION_PLAN',
] as const;

export type CatalogType = typeof catalogTypes[number];

export interface SquareMoneyDto {
  amount?: string | null;
  currency?: string;
}

export type SquareOrderDataDto = {
  tenantId: string;
  reservation: ReservationDto;
  reservationAddons: ReservationAddonDto[];
  venue: VenueDto;
  currentPackage?: PackageNameDto;
  username?: string;
};

export type SquareCreateOrderDto = {
  id?: string;
  locationId: string;
  referenceId?: string | null;
  customerId?: string | null;
  metadata?: Record<string, string> | null;
  createdAt?: string;
  updatedAt?: string;
  closedAt?: string;
  state?: string;
  version?: number;
  totalMoney?: SquareMoneyDto;
  totalTaxMoney?: SquareMoneyDto;
  totalDiscountMoney?: SquareMoneyDto;
  totalTipMoney?: SquareMoneyDto;
  totalServiceChargeMoney?: SquareMoneyDto;
  ticketName?: string | null;
  netAmountDueMoney?: SquareMoneyDto;
}

export type SquareOrderDtoRes = { isRexSquareEqual: boolean; squareOrder: SquareCreateOrderDto }
