import { isValidPhoneNumber } from "react-phone-number-input";

const regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const INVALID_DOMAINS = ['con']
const isValidDomain = (email: string): boolean => {
  const parts = email.split('.')
  return INVALID_DOMAINS.includes(parts[parts.length - 1])
}

export const isValidPhone = (phone: string): boolean => {
  return isValidPhoneNumber(phone);
};

export const isValidEmail = (email?: string): boolean => {
  const normalizedEmail = email?.toLowerCase();

  return !!normalizedEmail && regEmail.test(normalizedEmail) && !isValidDomain(normalizedEmail);
};
export const isValidEmailList = (emails: string): boolean => {
  return emails.split(',').every(email => !!email && regEmail.test(email));
}
