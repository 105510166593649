import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    useTheme,
} from "@material-ui/core";
import classNames from "classnames";
import { getSearch, replace } from "connected-react-router";

import { CompoundButton } from "office-ui-fabric-react";
import React, {
    ChangeEvent,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import { ConnectedProps, connect } from "react-redux";
import HTMLRenderer from "../../../../common/components/HTMLRenderer/HTMLRenderer";
import {
    formatDuration,
    formatDurationRange,
    formatPrice,
    formatPriceBasedOnValue,
    getPackageAssignedDuration,
    getPackageDurations,
} from "../../../../common/utils/formats";
import { GuestsWithAgeGroup } from "../../../../server/src/dto/guestsWithAgeGroup";
import { AgeGroupPricing } from "../../../../server/src/utils/ageGroups";
import AccountIcon from "../../../assets/account-icon.svgr";
import defaultImage from "../../../assets/default-image.jpg";
import InfoIcon from "../../../assets/info.svgr";
import PlusIcon from "../../../assets/plus-icon.svg";
import PlusTabletIcon from "../../../assets/plus-tablet-icon.svg";
import TimeIcon from "../../../assets/time.svgr";
import {
    addActivityAction,
    clearActivityByIdAction,
    pushUrlPathAction,
    setReservationErrorAction,
    startBookingAction,
    updateActivityAction
} from "../../actions/reservation-actions";
import {
    changeActivityAction,
    changeVenuePackageWithReservationAction,
    toPartyWithVenueAction
} from "../../actions/venue-actions";
import { useViewport } from "../../hooks/responsive";
import { selectAuthenticated, selectClient } from "../../reducers/auth-reducer";
import {
    selectIsUpdateReservation,
    selectReservation,
    selectSelectedActivities,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import {
    selectAvailability,
    selectCurrentActivity,
    selectIsLoadingActivity,
    selectIsLoadingVenue,
    selectVenue
} from "../../reducers/venues";
import {
    ActivityName,
    Availability,
    CurrencyType,
    PackageName,
    Pricing,
    State,
} from "../../store/types";
import { TimeButtonType } from "../MakePackageReservation/MakePackageReservation";
import MemberSignInButton from "../Memberships/MemberSignInButton";
import NoAvailiableGuests from "../PackageCard/NoAvailiableGuests";
import TimeButtonsSkeletons from "../PackageCard/TimeButtonsSkeletons";
import "../PackageCard/packageCard.scss";
import BootstrapTooltip from "../PackageCard/tooltip";
import ButtonMain from "../common/ButtonMain/ButtonMain";
import { mobileDropshodow } from "../common/MobileAttachedButton/utils";
import SelectGuestAge from "../common/SelectWithIcon/SelectGuestAge";
import SelectWithIcon from "../common/SelectWithIcon/SelectWithIcon";
import SwipeableBottomDrawer from "../common/SwipeableDrawer/SwipeableBottomDrawer";
import CustomSwiper from "../common/Swiper/CustomSwiper";
import TrashIcon from '../../../assets/trash.svgr';
import { BookingStep } from "../../utils/steps";
import TimeSelection, { timeButtonStyleV2 } from "../common/TimeCardLoader/TimeSelection";

const mapDispatchToProps = {
    changeActivity: changeActivityAction,
    changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
    toPartyWithVenue: toPartyWithVenueAction,
    replace: replace,
    pushUrlPath: pushUrlPathAction,
    setReservationError: setReservationErrorAction,
    updateActivity: updateActivityAction,
    addActivity: addActivityAction,
    startBooking: startBookingAction,
    clearActivityById: clearActivityByIdAction
};

const mapStateToProps = (state: State) => ({
    reservation: selectReservation(state),
    uiConfig: selectUIConfig(state),
    currentActivity: selectCurrentActivity(state),
    activeVenue: selectVenue(state),
    availability: selectAvailability(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    searchParams: getSearch(state),
    authenticated: selectAuthenticated(state),
    client: selectClient(state),
    selectedActivities: selectSelectedActivities(state),
    isLoadingActivity: selectIsLoadingActivity(state)

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = {
    packageName: ActivityName;
    onChangeActivity: (packageName: ActivityName, guests: number) => void;
    setDuration: (packageName: ActivityName, duration: number | null) => void;
    timeButtonsGroups: TimeButtonType[][];
    availability: Availability;
    slots: number;
    toggleShowMoreInfo: () => void;
    isShowMoreInfo: boolean;
    isLessTreeTimeSlots: boolean;
    error?: string;
    dataTestId?: string;
} & ConnectedProps<typeof connector>;


const getMenuPackagePropsGuests2: (
    minGuests: number,
    maxGuests: number,
    includeAdditionalOption?: boolean
) => string[] = (
    minGuests: number,
    maxGuests: number,
    includeAdditionalOption?: boolean
) => {
        const start = minGuests;
        const count = maxGuests - start + 1;

        const result = Array.from({ length: count }, (_, index) => {
            const value = start + index;
            return value.toString();
        });

        // Conditionally add an additional option if the condition is true
        if (includeAdditionalOption) {
            result.push(maxGuests + 1 + "+");
        }

        return result;
    };

function getNumberOfGuests(queryString: string): number | null {
    // Use URLSearchParams to parse the query string
    const params = new URLSearchParams(queryString);

    // Get the 'guests' parameter
    const guests = params.get("guests");

    // Convert the guests value to a number and return it
    return guests ? parseInt(guests, 10) : null;
}

function isPackageSelected(queryString: string): boolean {
    // Use URLSearchParams to parse the query string
    const params = new URLSearchParams(queryString);

    const packageExist = params.get("currentPackage");

    return !!packageExist;
}
const packageCardOffset = '100px'

const ActivityCard = ({
    packageName,
    currentActivity,
    uiConfig,
    reservation,
    onChangeActivity,
    activeVenue,
    setDuration,
    timeButtonsGroups: timeButtonsGroupsAll,
    changeActivity,
    isUpdateReservation,
    toPartyWithVenue,
    addActivity,
    isLoadingVenue,
    toggleShowMoreInfo,
    isShowMoreInfo,
    isLessTreeTimeSlots,
    error,
    searchParams,
    dataTestId,
    authenticated,
    pushUrlPath,
    client,
    setReservationError,
    updateActivity,
    selectedActivities,
    isLoadingActivity,
    clearActivityById
}: Props) => {
    const activity = selectedActivities?.find((a) => a.packageId === currentActivity?.id);
    const getAgeBasedGroups = () => {
        if (isUpdateReservation && reservation.guestsWithAgeGroups && reservation.guestsWithAgeGroups.length > 0) {
            return reservation.guestsWithAgeGroups;
        }
        return packageName?.ageGroups?.map((group) => ({
            ageGroup: group.groupName,
            count: group.minQty,
        })) || [];
    }
    const theme = useTheme()
    const [guestsWithAgeGroups, setGuestsWithAgeGroups] = useState<
        GuestsWithAgeGroup[]
    >(
        getAgeBasedGroups()
    );
    const [errorSelect, setErrorSelect] = useState<string>("");
    const MORE_THAN_11 = 100;
    const packageIsActive = currentActivity?.id === packageName.id;
    const contactUsButtonText = activeVenue?.contactUsButtonText;
    const currency = uiConfig?.currency || CurrencyType.USD;

    const [isLoadingContinue, setIsLoadingContinue] = useState(false);
    const { isMobile, isMiddleScreen, isTabletScreen } = useViewport();
    const menuPackagePropsGuest = getMenuPackagePropsGuests2(
        packageName?.minGuests || 0,
        packageName?.maxGuests || 10,
        !packageName?.hideInquiryRedirect
    );
    const isSelectedActivity = reservation.reservationActivities?.some(activity => activity.packageId === packageName.id);

    const ageGroups = packageName?.ageGroups || [];
    const selectGuestsOption = (
        event: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
        setReservationError('')

        if (!event.target.value) {
            return;
        }
        if ((event.target.value as string).includes("+")) {
            toPartyWithVenue(date);
            return;
        }
        const guests = Number(
            (event.target.value as number).toString().match(/\d+/)?.[0]
        );
        setGuestsCount(guests);

        if (guests === MORE_THAN_11) {
            toPartyWithVenue(date);
            return;
        }
        changeActivity({
            updatePackage: currentActivity,
            date,
            guests,
            selectDuration: activity?.duration,
        });
    };
    const applyGuestsWithAgeGroup = (guests: GuestsWithAgeGroup[]) => {
        setReservationError('')
        updateActivity({ guestsWithAgeGroups: guests, packageId: packageName.id, packageName: packageName.name, });
        setGuestsWithAgeGroups(guests);
        const guestsCount = guests.reduce((acc, g) => acc + g.count, 0);
        setGuestsCount(guestsCount);

        changeActivity({
            updatePackage: currentActivity,
            date,
            guests: guestsCount,
            selectDuration: activity?.duration,
            guestsWithAgeGroups: guests,
            isUpdateExisting: true
        });
    };


    let durationList: number[] = [];
    if (packageName.enableDurationChoice) {
        durationList = getPackageDurations(packageName);
    }
    let durationSlots = durationList.map((d) => {
        const textButton = formatDuration(d, activeVenue?.timeSlotDuration);
        return { key: d, text: textButton };
    });

    const renderPrice = () => {
        if (packageName.hidePrice) return null;

        const minPrice = packageName.price
            ? formatPriceBasedOnValue(+packageName.price, currency, true)
            : "";
        const discountedPrice = formatPriceBasedOnValue(packageName?.discountedPrice || 0, currency, true, true)
        const actionText = uiConfig?.actionText?.toLocaleLowerCase();

        let priceMethod;



        switch (packageName.method) {
            case Pricing.perPerson:
                priceMethod = `/person`;
                break;

            case Pricing.perLane:
                priceMethod = `/hour`;
                break;

            case Pricing.flatRate:
                priceMethod = "";
                break;

            case Pricing.flatRatePerLane:
                priceMethod = `/${actionText || "lane"}`;
                break;

            case Pricing.flatRatePerHour:
                priceMethod = `/hour`;
                break;

            default:
                priceMethod = `/person`;
        }

        if (packageName?.resourceName) {
            priceMethod = `/${packageName.resourceName}`;
        }

        if (packageName?.perPersonLabel) {
            priceMethod = `/${packageName.perPersonLabel}`
        }

        return (
            !packageName.contactOnly && (
                <Typography className="package-card__title-v2 package-card__price-v2">
                    {(packageName.enableDiscountedPrice && discountedPrice) ?
                        <Box display={'flex'} style={{ gap: '8px' }}>
                            <Typography className="package-card__title-v2 package-card__price-discounted">{discountedPrice}</Typography>
                            <Typography className="package-card__title-v2 package-card__price-important">{minPrice}</Typography>
                        </Box>
                        : minPrice}
                    {(!packageName.enableDiscountedPrice || !packageName.discountedPriceNote) && <span className="package-card__price-from">from</span>}
                    <span className="package-card__price-method">{priceMethod}</span>
                </Typography>
            )
        );
    };

    const [isAgePolicyChecked, setIsAgePolicyChecked] = useState(false);
    const isDurationVisible =
        !uiConfig?.hideDuration &&
        !packageName?.hidePackageDuration &&
        packageName.enableDurationChoice;

    const isShowReservationTime = uiConfig?.showReservationTime;

    useEffect(() => {
        if (
            currentActivity?.id === packageName.id &&
            !isShowMoreInfo &&
            !isLoadingVenue
        ) {
            toggleShowMoreInfo();
        }
    }, [currentActivity?.id]);

    const handleShowMoreInfo = () => {
        if (isLoadingVenue && isShowMoreInfo) {
            toggleShowMoreInfo();
            onChangeActivity(packageName, Number(guestsCount));
        } else {
            toggleShowMoreInfo();

            if (isShowMoreInfo)
                setTimeout(() => {
                    onChangeActivity(packageName, Number(guestsCount));
                }, 400);
            else onChangeActivity(packageName, Number(guestsCount));
        }

    };

    const handleClear = () => {
        setGuestsCount(null);
        setGuestsWithAgeGroups([]);
        setReservationError('');
        toggleShowMoreInfo();
        clearActivityById(packageName.id);
    }
    const formatGuestRange = (minGuests: number, maxGuests: number) => {
        return minGuests === maxGuests
            ? `${minGuests} Guests`
            : `${minGuests}-${maxGuests} Guests`;
    };
    const onContinue = () => {
        if (!activeVenue || !currentActivity) {
            return;
        }
        setIsLoadingContinue(true);

        if (
            (guestsCount &&
                (guestsCount > currentActivity.maxGuests ||
                    guestsCount < currentActivity.minGuests)) ||
            !guestsCount
        ) {
            setIsLoadingContinue(false);
            return;
        }

        addActivity({
            venue: activeVenue,
            date,
            currentActivity,
        });
        toggleShowMoreInfo();
    };

    const renderDuration = () => {
        if (packageName?.hidePackageDuration) {
            return <></>;
        }
        let packageDuration = packageName.duration;

        if (packageName?.enableAssignDuration) {
            const { guests } = reservation;
            packageDuration = getPackageAssignedDuration(packageName, guests);
        }

        let content = (
            <span>
                {formatDuration(packageDuration, activeVenue?.timeSlotDuration)}
            </span>
        );

        if (packageName.enableDurationChoice) {
            const minimumDuration = Math.min(...durationList);
            const maximumDuration = Math.max(...durationList);

            const duration = formatDurationRange(
                [minimumDuration, maximumDuration],
                activeVenue?.timeSlotDuration
            );
            content = (
                <span>
                    {isMobile || isTabletScreen
                        ? duration
                            .replace("Hour", "Hr")
                            .replace(/(\d+)\s*-\s*(\d+)/g, "$1-$2")
                        : duration}
                </span>
            );
        }

        return (
            <Box className="package-card__bottom">
                <TimeIcon />
                <Typography className="package-card__guests-v2 bold">
                    {content}
                </Typography>
            </Box>
        );
    };

    const getIsMemberPlanAvailableForPackage = () => {
        if (!packageName.membershipPlanAssignedIds || packageName.membershipPlanAssignedIds.length === 0) return true;

        if (packageName.membershipPlanAssignedIds.length !== client?.subscriptions.length) return false;

        for (const id of packageName.membershipPlanAssignedIds) {
            if (!client?.subscriptions?.some((sub) => sub.plan.id === id)) {
                return false;
            }
            if (client?.subscriptions?.some((sub) => sub.plan.id === id && !sub.active)) {
                return false;
            }
        }

        return true;

    };
    const renderButton = () => {
        if ((packageName.isMemberOnlyPackage && !getIsMemberPlanAvailableForPackage())) return <MemberSignInButton onClick={() => pushUrlPath(!authenticated ? "/reservation-info/account" : "/memberships")} />;
        const isActivitySelected = reservation?.reservationActivities?.some(activity => activity.packageId === packageName.id);

        return (
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    handleShowMoreInfo();
                }}
                className={`btn-activity root bold ${isLoadingVenue ? "loading" : ""} ${isTabletScreen ? "tablet" : "desktop"
                    }`}
                disabled={isLoadingVenue}
                style={{ backgroundColor: isActivitySelected ? '#2E3A48' : '#EBECF0', color: isActivitySelected ? 'white' : 'black' }}
            >

                {
                    isActivitySelected ? 1 :
                        <img
                            src={isTabletScreen ? PlusTabletIcon : PlusIcon}
                            alt="PlusIcon"
                            style={{
                                transform: isShowMoreInfo ? "rotate(45deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease-in-out",
                            }}
                        />
                }
            </Button>
        );
    };

    const renderDesktopView = () => {
        return (
            <>
                <Box
                    className={`package-card__image-container-v2 ${isMobile ? "mobile" : ""
                        } ${isTabletScreen ? "tablet" : ""}`}
                >
                    <img
                        src={packageName.image || defaultImage}
                        alt={packageName.name}
                        className={`package-card__image-v2 ${isMobile ? "mobile" : ""}`}
                        style={isShowMoreInfo ? { borderBottomLeftRadius: 0 } : {}}
                    />
                    {/*<span className='package-card__tag'>NEW</span>*/}
                </Box>
                <Box
                    className={`package-card__content-v2 ${isMobile ? "mobile" : ""} ${isTabletScreen ? "tablet" : ""
                        }`}
                >
                    {packageName.enableDiscountedPrice && packageName?.discountedPriceNote ?
                        <Box className={`package-card__discounted-price-note body-small-white ${isMobile ? "mobile" : ""}`}>
                            {packageName.discountedPriceNote}
                        </Box> : null}
                    <Box mb={isMobile ? "12px" : 0}>
                        <Box className="package-card__content-top-v2">
                            <Typography className="package-card__title-v2 ellipsis">
                                {packageName.required && <span className='required-star'>*</span>}{packageName.name}
                            </Typography>
                            {renderPrice()}
                        </Box>
                        <Typography className="package-card__short-description-v2">
                            {packageName.shortDescription}
                        </Typography>
                    </Box>
                    <Box
                        className={`package-card__actions-v2 ${durationSlots.length > 0 && !packageName.hidePackageDuration
                            ? ""
                            : "package-card__without-selector"
                            }`}
                    >
                        <Box className="package-card__bottom-container">
                            <Box className="package-card__bottom">
                                <AccountIcon />
                                <Typography className="package-card__guests-v2 bold">
                                    {formatGuestRange(
                                        packageName.minGuests,
                                        packageName.maxGuests
                                    )}
                                </Typography>
                            </Box>
                            {renderDuration()}
                        </Box>
                        {renderButton()}
                    </Box>
                </Box>
            </>
        );
    };
    const { date } = reservation;
    const isOriginalPackageGuestSelectionAllowed = reservation.guests >= packageName.minGuests && reservation.guests <= packageName.maxGuests;
    const defaultGuests = isOriginalPackageGuestSelectionAllowed ? reservation.guests : packageName?.minGuests;

    const [guestsCount, setGuestsCount] = useState<number | null>(activity?.guests || defaultGuests || 5);

    const durationCount = durationSlots.find((dur) => dur.key === activity?.duration)?.text;


    const setTimeSlot = (slot: number) => {
        setReservationError('')
        updateActivity({ slots: slot, packageId: packageName.id, packageName: packageName.name });
    };

    const noAvailiableGuests =
        (timeButtonsGroupsAll.length === 0 && !isLoadingVenue) || error;
    const accordionDivRef = useRef<HTMLDivElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);
    const isInitialLoadSlots = timeButtonsGroupsAll.length === 0 && isLoadingVenue && isMobile;
    function scrollToContainer(ref?: RefObject<HTMLDivElement>) {
        const cardElement = ref?.current || cardRef.current;
        if (!cardElement) return;

        cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    let show21CheckBox = false;
    if (activeVenue?.timeSlots) {
        const slot = activeVenue.timeSlots.find((s: any) => s.time === activity?.slots);
        show21CheckBox = slot && !closed ? slot.is21plus : false;
    }


    const disableContinueButton = !guestsCount || isLoadingActivity || !activity?.slots;

    const updateAccordionHeight = () => {
        const contentDiv = accordionDivRef.current;

        if (contentDiv) {
            // Get all child elements inside the accordion
            const childElements = Array.from(contentDiv.children) as HTMLElement[];

            // Calculate total height including padding and margins
            const totalHeight = childElements.reduce(
                (acc, element) => acc + element.offsetHeight,
                0
            );

            accordionDivRef.current!.style!.height! = isShowMoreInfo
                ? `${totalHeight}px`
                : "0";
        }
    };
    useEffect(() => {
        if (!isMobile) {
            if (isShowMoreInfo) {
                if (
                    !isMobile &&
                    isPackageSelected(searchParams) &&
                    !(currentActivity?.id === packageName.id) &&
                    uiConfig?.scrollToTimeSlotAfterSelect
                ) {
                    setTimeout(() => {
                        scrollToContainer();
                        updateAccordionHeight();
                    }, 300);
                } else if (
                    !isMobile &&
                    !isPackageSelected(searchParams) &&
                    !(currentActivity?.id === packageName.id) &&
                    uiConfig?.scrollToTimeSlotAfterSelect
                ) {
                    scrollToContainer();
                    updateAccordionHeight();
                } else {
                    updateAccordionHeight();
                }
            } else {
                accordionDivRef.current?.style.setProperty("height", "0");
            }
        }
    }, [packageIsActive, isShowMoreInfo]);

    useEffect(() => {
        if (!isMobile) {
            if (isShowMoreInfo) {
                updateAccordionHeight();
            } else {
                accordionDivRef.current?.style.setProperty("height", "0");
            }
        }
    }, [accordionDivRef.current?.innerHTML]);
    const firstContainerRef = useRef<HTMLDivElement>(null);
    const [firstContainerWidth, setFirstContainerWidth] = useState<number>(0);
    const updateFirstContainerWidth = () => {
        const firstContainer = firstContainerRef.current;
        if (firstContainer) {
            setFirstContainerWidth(firstContainer.clientWidth);
        }
    };
    useEffect(() => {
        updateFirstContainerWidth();

        window.addEventListener("resize", updateFirstContainerWidth);
        return () => {
            window.removeEventListener("resize", updateFirstContainerWidth);
        };
    }, []);


    const getTimeButtonsClass = () => {
        let timeButtonClass = "";
        if (isLessTreeTimeSlots) {
            timeButtonClass = "fullWidth";
        } else if (!isShowReservationTime) {
            if (isMiddleScreen) {
                timeButtonClass = isMobile ? "mobile" : "";
            }
        } else {
            timeButtonClass = isMobile ? "small" : "middle";
        }
        return timeButtonClass;
    };
    function ageGroupToString(obj?: AgeGroupPricing) {
        if (!obj) return '';
        return Object.entries(obj)
            .map(([key, value]) => `${ageGroups.find(ag => ag.groupName === key)?.abbreviation || key}: ${formatPrice(value.rate || 0, currency)}`)
            .join(' \n');
    }
    const isAgeGroupBased =
        packageName?.ageGroups &&
        (packageName?.method === Pricing.ageGroupsFlatRate ||
            packageName?.method === Pricing.ageGroupsPerHour);


    const renderPriceForTimeSlot = (t: TimeButtonType, isPressed: boolean) => {
        if (packageName.hidePriceOnTimeCard) return null;

        return <Box
            className={`price2 ${isTabletScreen ? "tablet" : ""
                }  ${isPressed ? "pressed" : ""}`}
        >
            {/* {index === 0 && <span className="price2-sale">{'$49.00'}</span>} */}
            {isAgeGroupBased ? (
                <Box
                    display={"flex"}
                    style={{ gap: "4px" }}
                    alignItems={"center"}
                >
                    <Typography className="body-small">
                        See Prices
                    </Typography>
                    <BootstrapTooltip
                        // age group pricing is not defined
                        title={ageGroupToString(t.ageGroupPricing) || ''}
                        placement="top"
                    >
                        <Box display={"flex"}>
                            <InfoIcon />
                        </Box>
                    </BootstrapTooltip>
                </Box>
            ) : (
                t.price
            )}
        </Box>
    }

    const renderActiveView = () => {
        return (
            <div
                ref={accordionDivRef}
                style={{
                    transition: "height 0.3s ease-in-out",
                    overflowY: isMobile ? "auto" : "hidden",
                    overflowX: "hidden",
                }}
            >
                <Box
                    className={`package-card__info-content-container-v2 ${isMobile ? "mobile" : ""
                        } ${isTabletScreen ? "tablet" : ""}`}
                    style={
                        isMobile && !noAvailiableGuests ? { paddingBottom: "90px" } : {}
                    }
                    data-testid={"package-containers"}
                >
                    {isMobile ? (
                        <Box
                            className="package-card__content-v2"
                            p={0}
                            pb={"32px"}
                            mb={"32px"}
                            borderBottom={"1px solid rgba(0, 0, 0, 0.05)"}
                        >
                            {packageName.enableDiscountedPrice && packageName?.discountedPriceNote ?
                                <Box className={`package-card__discounted-price-note body-small-white ${isMobile ? "mobile" : ""}`}>
                                    {packageName.discountedPriceNote}
                                </Box> : null}
                            <Box mb={0}>
                                <Box className="package-card__content-top-v2">
                                    <Typography className="package-card__title-v2 ellipsis">
                                        {packageName.name}
                                    </Typography>
                                    {renderPrice()}
                                </Box>
                                <Typography
                                    className="package-card__short-description-v2"
                                    style={{ marginBottom: "20px" }}
                                >
                                    {packageName.shortDescription}
                                </Typography>
                            </Box>
                            <Box
                                className={`package-card__actions-v2 ${durationSlots.length > 0 && !packageName.hidePackageDuration
                                    ? ""
                                    : "package-card__without-selector"
                                    }`}
                            >
                                <Box className="package-card__bottom-container">
                                    <Box className="package-card__bottom">
                                        <AccountIcon />
                                        <Typography className="package-card__guests-v2 bold">
                                            {packageName.minGuests}-{packageName.maxGuests} {packageName.guestsLabel || 'Guests'}
                                        </Typography>
                                    </Box>
                                    {renderDuration()}
                                </Box>
                            </Box>
                        </Box>
                    ) : null}

                    <HTMLRenderer
                        className="package-card__info-content-v2 overflow-auto break-word"
                        html={packageName.description}
                        withExtractInnerTags
                    />

                    <Box>
                        <Typography className="heading-h6 package-card__info-title">
                            {packageName.reservationDetails || "Reservation Details"}
                        </Typography>
                        <div ref={firstContainerRef}>
                            <Box
                                className={`package-card__info-row package-card__info-content-container ${isMobile ? "mobile" : ""
                                    }`}
                            >
                                {isMobile && (
                                    <div ref={calendarRef} style={{ scrollMarginTop: 0 }} />
                                )}

                                {isAgeGroupBased ? (
                                    <SelectGuestAge
                                        options={ageGroups}
                                        label={packageName.guestsLabel || 'Guests'}
                                        guestsWithAgeGroups={guestsWithAgeGroups}
                                        applyGuestsWithAgeGroup={applyGuestsWithAgeGroup}
                                        errorSelect={errorSelect}
                                        width={
                                            isMobile ? "100%" : isDurationVisible ? "50%" : "100%"
                                        }
                                        isDurationVisible={isDurationVisible}
                                        truncateLabel
                                        minPackageGuests={packageName.minGuests}
                                        maxPackageGuests={packageName.maxGuests}
                                        currency={currency}
                                        ageGroupsPricingNote={activeVenue?.ageGroupsPricingNote}
                                    />
                                ) : (
                                    <SelectWithIcon
                                        errorSelect={isMobile ? error : ""}
                                        width={
                                            isMobile ? "100%" : isDurationVisible ? "50%" : "100%"
                                        }
                                        label={packageName.guestsLabel || 'Guests'}
                                        options={menuPackagePropsGuest}
                                        value={guestsCount}
                                        onChange={selectGuestsOption}
                                    />
                                )}

                                {isDurationVisible ? (
                                    <SelectWithIcon
                                        width={isMobile ? "100%" : "50%"}
                                        label="Duration"
                                        options={durationSlots.map((dur) => dur.text)}
                                        value={
                                            durationCount ||
                                            (durationSlots &&
                                                durationSlots.length > 0 &&
                                                durationSlots[0].text)
                                        }
                                        onChange={(e) =>
                                            setDuration(
                                                packageName,
                                                durationSlots.find((dur) => dur.text === e.target.value)
                                                    ?.key || 0
                                            )
                                        }
                                    />
                                ) : null}
                            </Box>
                        </div>

                        <Box
                            className={
                                noAvailiableGuests
                                    ? ""
                                    : "package-card__info-content-container-time"
                            }
                        >
                            {(timeButtonsGroupsAll.length &&
                                timeButtonsGroupsAll.length !== 0) ||
                                isLoadingVenue ? (
                                <Typography className="heading-h6 package-card__info-title">
                                    {packageName?.selectTime || "Select Time"}
                                </Typography>
                            ) : null}
                            <TimeSelection
                                timeButtonsGroupsAll={timeButtonsGroupsAll}
                                isMobile={isMobile}
                                isTabletScreen={isTabletScreen}
                                isShowReservationTime={isShowReservationTime || false}
                                getTimeButtonsClass={getTimeButtonsClass}
                                renderPriceForTimeSlot={renderPriceForTimeSlot}
                                setTimeSlot={setTimeSlot}
                                activeVenue={activeVenue}
                                firstContainerWidth={firstContainerWidth}
                                isAgePolicyChecked={isAgePolicyChecked}
                                setIsAgePolicyChecked={setIsAgePolicyChecked}
                                isLoadingVenue={isLoadingActivity}
                                packageName={packageName}
                                show21CheckBox={show21CheckBox}
                                slots={activity?.slots || 0}
                                currency={currency}
                            />
                        </Box>
                        {(noAvailiableGuests && !isLoadingActivity) && (
                            <NoAvailiableGuests
                                noSpace
                                text={
                                    activeVenue?.inactivePackageMessage ? (
                                        <HTMLRenderer html={activeVenue?.inactivePackageMessage} />
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        )}


                        {(noAvailiableGuests || isInitialLoadSlots) ? null : (
                            <>
                                <Box height={"12px"} />
                                <Box display={'flex'} style={{ gap: '16px' }}>
                                    {isSelectedActivity && <Button className='btn-trash' onClick={handleClear}>
                                        <TrashIcon />
                                    </Button>}
                                    {(isMobile) ? (
                                        (<Box
                                            position={"fixed"}
                                            width={"100%"}
                                            p={"24px"}
                                            bottom={0}
                                            left={0}
                                            bgcolor={"white"}
                                            zIndex={1000}
                                            style={{ filter: mobileDropshodow }}
                                        >
                                            <ButtonMain
                                                disabled={disableContinueButton}
                                                onClick={onContinue}
                                                buttonClassName="btn-activity-continue"
                                            >
                                                Add
                                            </ButtonMain>
                                        </Box>)
                                    ) : (
                                        <ButtonMain
                                            disabled={disableContinueButton}
                                            onClick={onContinue}
                                        >
                                            Add
                                        </ButtonMain>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </div >
        );
    };

    return (
        <>
            <article
                className={classNames("package-card-v2", {
                    "is-active": packageIsActive,
                    "is-mobile": isMiddleScreen,
                })}
                style={{
                    scrollMarginTop: packageCardOffset,
                    cursor: !isShowMoreInfo ? "pointer" : "default",
                }}
                onClick={
                    (packageName.isMemberOnlyPackage && !getIsMemberPlanAvailableForPackage())
                        ? () => pushUrlPath(authenticated ? "/memberships" : "/reservation-info/account")
                        : !isShowMoreInfo
                            ? handleShowMoreInfo
                            : () => { }
                }
                ref={cardRef}
            >
                <Box
                    className={`package-card-v2-inside ${isMobile ? "mobile" : ""}`}
                    data-testid={dataTestId || "package-cards"}
                >
                    {renderDesktopView()}
                </Box>
                {!isMobile ? (
                    renderActiveView()
                ) : (
                    <SwipeableBottomDrawer
                        open={isShowMoreInfo}
                        onClose={handleShowMoreInfo}
                    >
                        {renderActiveView()}
                    </SwipeableBottomDrawer>
                )}
            </article>
        </>
    );
};

export default connector(ActivityCard);